import React, { useState,  useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import ProfissionalService from "../../../services/ProfissionalService";
import EmpresaProfissionalService from "../../../services/EmpresaProfissionalService";

const DoctorList = () => {
   const [profissionais, setProfissionais] = useState([]);
   const [params, setParams] = useState({
      page: 1,
      nome: ""
   });
   const navigate = useNavigate();
   const handleInputChange = (e) => {
      params.nome = e.target.value;
      setParams(params);
   }

   const loadData = async () => {
      const profissionalRetorno = await EmpresaProfissionalService.getEmpresaProfissionais(params);
      setProfissionais(profissionalRetorno);
   };


   const handleSubmit = (e) => {
      e.preventDefault();
      loadData();
   };
   useEffect(() => {
      loadData();
   }, []);


   const remover = (id) => {
      ProfissionalService.deleteProfissional(id).then(() => {
         loadData();
         swal("Registro removido com sucesso!", {
            icon: "success",
         });

      })
   }

   return (
      <>
         <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="me-auto d-none d-lg-block">
               <Link
                  to="/profissional"
                  className="btn btn-primary btn-rounded"
               >
                  + Novo
               </Link>
            </div>
            <form onSubmit={handleSubmit}>
               <div className="input-group search-area ms-auto d-inline-flex me-3">
                  <input
                     type="text"
                     className="form-control"
                     placeholder="Buscar.."
                     onChange={handleInputChange}
                  />
                  <div className="input-group-append">
                     <button type="submit" className="input-group-text" onClick={() => { loadData() }}>
                        <i className="flaticon-381-search-2" />
                     </button>
                  </div>

               </div>
               {/* <Link to="/patient-list" className="settings-icon">
               <i className="flaticon-381-settings-2 me-0" />
            </Link> */}
            </form>

         </div>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="table-responsive table-striped">
                        <div
                           id="patient_list"
                           className="dataTables_wrapper no-footer"
                        >
                           <table
                              id="example5"
                              className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                              role="grid"
                              aria-describedby="example5_info"
                           >
                              <thead>
                                 <tr role="row">
                                    <th>
                                       Nome
                                    </th>
                                    <th>
                                       Email
                                    </th>
                                    <th>
                                       Celular
                                    </th>
                                    <th>
                                       Nascimento
                                    </th>
                                    <th></th>

                                 </tr>
                              </thead>
                              <tbody>
                                 {profissionais && (profissionais.map(pessoa => (
                                    <tr role="row">
                                       <td>{pessoa.profissional.nome}</td>
                                       <td>{pessoa.profissional.email}</td>
                                       <td>{pessoa.profissional.celular}</td>
                                       <td>{pessoa.profissional.data_nascimento}</td>
                                       <td>
                                          <span className="me-3">
                                             <Button variant='primary tp-btn-light' onClick={() => { navigate(`/profissional-detalhes/${pessoa.profissional.id}`) }}><i className="fa fa-eye fs-18"></i></Button>
                                          </span>
                                          <span className="me-3">
                                             <Button variant='primary tp-btn-light' onClick={() => { navigate(`/profissional/${pessoa.profissional.id}`) }}><i className="fa fa-pencil fs-18 text-success"></i></Button>
                                          </span>
                                          <span className="me-3">
                                             <Button variant='danger tp-btn-light' onClick={() =>
                                                swal({
                                                   title: "Confirma a exclusão?",
                                                   text: "",
                                                   icon: "warning",
                                                   buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                   dangerMode: true,

                                                }).then((willDelete) => {
                                                   if (willDelete) {
                                                      remover(pessoa.id)
                                                   }
                                                })
                                             }><i className="fa fa-trash fs-18 text-danger"></i></Button>
                                          </span>
                                       </td>
                                    </tr>
                                 )))}

                              </tbody>
                           </table>

                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </>
   );
};

export default DoctorList;
