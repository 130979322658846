import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class AgendaService {

  async getAgenda(params) {

    // const cacheKey = `agendas-${params.visao}-${params.data_inicio}-${params.data_fim}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/agendas`, { params });
   // SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }
  async getAgendaPacientes(params) {

    // const cacheKey = `agendas-${params.visao}-${params.data_inicio}-${params.data_fim}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/agendas/pacientes`, { params });
   // SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }

  async getAgendaById(id) {
    const cacheKey = `agendas-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/agendas/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createAgenda(data) {
    SessionService.removeAll(`agendas`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/agendas', data);
      return response.data;
    } else {
      return await this.updateAgenda(data.id, data);
    }

  }

  async updateAgenda(id, data) {
    SessionService.removeAll(`agendas`);
    const response = await axiosInstance2.put(`/agendas/${id}`, data);
    return response.data;

  }


  async deleteAgenda(id) {
    SessionService.removeAll(`agendas`);
    const response = await axiosInstance2.delete(`/agendas/${id}`);
    return response.data;

  }
}

export default new AgendaService();
