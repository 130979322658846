import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class SalaService {

  async getSalas(params) {

    const cacheKey = `salas`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/salas`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getSalaById(id) {
    const cacheKey = `salas-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/salas/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createSala(data) {
    SessionService.removeAll(`salas`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/salas', data);
      return response.data;
    } else {
      return await this.updateSala(data.id, data);
    }

  }

  async updateSala(id, data) {
    SessionService.removeAll(`salas`);
    const response = await axiosInstance2.put(`/salas/${id}`, data);
    return response.data;

  }


  async deleteSala(id) {
    SessionService.removeAll(`salas`);
    const response = await axiosInstance2.delete(`/salas/${id}`);
    return response.data;

  }
}

export default new SalaService();
