import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';
class ProcedimentoTipoService {

  async getProcedimentoTipos(params) {
    const cacheKey = `procedimento-tipos`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
       return cachedData;
     }
      const response = await axiosInstance2.get(`/procedimento-tipos`,{ params });
      SessionService.addToCache(cacheKey, response.data);
      return response.data;

  }


  async getProcedimentoTipoById(id) {
      const response = await axiosInstance2.get(`/procedimento-tipos/${id}`);
      return response.data;

  }


  async createProcedimentoTipo(profissionalData) {
    if(profissionalData.id == null){
      const response = await axiosInstance2.post('/procedimento-tipos', profissionalData);
      return response.data;
    }else{
        return  await this.updateProcedimentoTipo(profissionalData.id, profissionalData);
    }

  }

  async updateProcedimentoTipo(id, profissionalData) {
      const response = await axiosInstance2.put(`/procedimento-tipos/${id}`, profissionalData);
      return response.data;

  }

 
  async deleteProcedimentoTipo(id) {
      const response = await axiosInstance2.delete(`/procedimento-tipos/${id}`);
      return response.data;

  }
}

export default new ProcedimentoTipoService();
