import { lazy, Suspense } from 'react';
/// Components
import Index from './jsx/index';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
// action

import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useProgressBar } from './services/AxiosInstance2';
import CustomProgressBar from './jsx/components/Custom/CustomProgressBar';


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function App(props) {
    const loading = useProgressBar();

    let routeblog = (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/page-register' element={<SignUp />} />
        </Routes>
    );
    if (props.isAuthenticated) {
        return (
            <>
                {loading && <CustomProgressBar />}
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routeblog}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = () => {
    return {
        // isAuthenticated: isAuthenticated(state),
        isAuthenticated: isAuthenticated()
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
