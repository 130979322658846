import React from "react";

const Footer = () => {
  //const data = new Date();
  return (
    <div className="footer">
      {/* <div className="copyright">
        <p>
          Copyright © Desenvolvido por{" "}
          <a href="/" target="_blank"  rel="noreferrer">
            Innoviclinic
          </a>{" "}
          {dat.getFullYear()}
        </p>
      </div> */}
    </div>
  );
};

export default Footer;
