import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class InteracaoService {

  async getInteracoes(params) {

    // const cacheKey = `interacoes`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/interacoes`, { params });
  //  SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getInteracaoById(id) {
    // const cacheKey = `interacoes-${id}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }

    const response = await axiosInstance2.get(`/interacoes/${id}`);
   // SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createInteracao(data) {
    SessionService.removeAll(`interacoes`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/interacoes', data);
      return response.data;
    } else {
      return await this.updateInteracao(data.id);
    }

  }

  async updateInteracao(id) {
    SessionService.removeAll(`interacoes`);

    const response = await axiosInstance2.put(`/interacoes/${id}`, {finalizado:true});
    return response.data;

  }


  async deleteInteracao(id) {
    SessionService.removeAll(`interacoes`);
    const response = await axiosInstance2.delete(`/interacoes/${id}`);
    return response.data;

  }
}

export default new InteracaoService();
