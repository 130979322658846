import * as Yup from 'yup';


let initialValues = {
  id: 0, // Valor inicial para id (pode ser 0 se for novo registro)
  //empresa_id: 0, // Valor inicial para empresa_id
  hora_ini_agenda: '',
  hora_fim_agenda: '',
  duracao_atendimento: '',
  visualizacao_agenda: '',
};

const validationSchema = Yup.object().shape({
  hora_ini_agenda: Yup.string().required('Campo obrigatório'),
  hora_fim_agenda: Yup.string().required('Campo obrigatório'),
  duracao_atendimento: Yup.string().required('Campo obrigatório'),
  visualizacao_agenda: Yup.string().max(100, 'Máximo de 100 caracteres permitidos'),
});

export { initialValues, validationSchema };

