import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class InteracaoAtendimentoService {

  async getInteracaoAtendimentos(params) {

    // const cacheKey = `interacao-atendimentos`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/interacao-atendimentos`, { params });
 //   SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getInteracaoAtendimentoById(id) {
    // const cacheKey = `interacao-atendimentos-${id}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }

    const response = await axiosInstance2.get(`/interacao-atendimentos/${id}`);
  //  SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createInteracaoAtendimento(data) {
    SessionService.removeAll(`interacao-atendimentos`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/interacao-atendimentos', data);
      return response.data;
    } else {
      return await this.updateInteracaoAtendimento(data.id, data);
    }

  }

  async updateInteracaoAtendimento(id, data) {
    SessionService.removeAll(`interacao-atendimentos`);
    const response = await axiosInstance2.put(`/interacao-atendimentos/${id}`, data);
    return response.data;

  }


  async deleteInteracaoAtendimento(id) {
    SessionService.removeAll(`interacao-atendimentos`);
    const response = await axiosInstance2.delete(`/interacao-atendimentos/${id}`);
    return response.data;

  }
}

export default new InteracaoAtendimentoService();
