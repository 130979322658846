import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class ConvenioService {

  async getConvenios(params) {

    const cacheKey = `convenios`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/convenios`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getConvenioById(id) {
    const cacheKey = `convenios-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/convenios/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createConvenio(data) {
    SessionService.removeAll(`convenios`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/convenios', data);
      return response.data;
    } else {
      return await this.updateConvenio(data.id, data);
    }

  }

  async updateConvenio(id, data) {
    SessionService.removeAll(`convenios`);
    const response = await axiosInstance2.put(`/convenios/${id}`, data);
    return response.data;

  }


  async deleteConvenio(id) {
    SessionService.removeAll(`convenios`);
    const response = await axiosInstance2.delete(`/convenios/${id}`);
    return response.data;

  }
}

export default new ConvenioService();
