import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class AgendaStatusService {

  async getAgendaStatus(params) {

    const cacheKey = `agenda-status`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/agenda-status`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getAgendaStatusById(id) {
    const cacheKey = `agenda-status-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/agenda-status/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createAgendaStatus(data) {
    SessionService.removeAll(`agenda-status`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/agenda-status', data);
      return response.data;
    } else {
      return await this.updateAgendaStatus(data.id, data);
    }

  }

  async updateAgendaStatus(id, data) {
    SessionService.removeAll(`agenda-status`);
    const response = await axiosInstance2.put(`/agenda-status/${id}`, data);
    return response.data;

  }


  async deleteAgendaStatus(id) {
    SessionService.removeAll(`agenda-status`);
    const response = await axiosInstance2.delete(`/agenda-status/${id}`);
    return response.data;

  }
}

export default new AgendaStatusService();
