import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';

import {
    loadingToggleAction,
} from '../../store/actions/AuthActions';
import { pessoaValidationSchema } from '../../models/Pessoa';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { signUp } from '../../services/AuthService';
import { toast } from 'react-toastify';
function Register(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    const onSignUp = (values, { setSubmitting, setErrors }) => {
        values.tipo_usuario =  "Profissional";
        values.celular = values.celular.replace(/[^\d+]/g, '');
        signUp(values).then(data => {
            dispatch(loadingToggleAction(true));
            toast.success("Cadastro efetuado com sucesso, verifique seu email para validar.", {
                position: "top-right",
                autoClose: 5000
            });
            navigate('/login');
        }).catch(error => {
            if (error?.response?.data?.errors) {
              setErrors(error?.response?.data?.errors);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });


    }
    return (
        <div className='authincation h-100 p-meddle'>
            <div className='container h-100'>
                <div className='row justify-content-center h-100 align-items-center'>
                    <div className='col-md-6'>
                        <div className='authincation-content'>
                            <div className='row no-gutters'>
                                <div className='col-xl-12'>
                                    <div className='auth-form'>
                                        <h4 className='text-center mb-4 '>Cadastre sua conta</h4>
                                        <Formik
                                            initialValues={{ email: '', celular: '', senha: '' }}
                                            validationSchema={pessoaValidationSchema}
                                            onSubmit={(values, { setSubmitting, setErrors }) => {
                                                setTimeout(() => {
                                                    onSignUp(values, { setSubmitting, setErrors });
                                                    setSubmitting(false);
                                                }, 400);
                                            }}
                                        >
                                            {({
                                                touched,
                                                errors,
                                            }) => (
                                                <Form>
                                                    <div className='form-group'>
                                                        <label className='mb-1 '>
                                                            <strong>Nome</strong>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="nome"
                                                            className={`form-control ${touched?.nome ? (errors.nome ? 'is-invalid' : 'is-valid') : ''}`}

                                                        />
                                                        <ErrorMessage name="nome" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className='mb-1 '>
                                                            <strong>Celular</strong>
                                                        </label>
                                                        <Field
                                                            name="celular"
                                                            as={InputMask}
                                                            mask="(99) 99999-9999"
                                                            className={`form-control ${touched?.celular ? (errors.celular ? 'is-invalid' : 'is-valid') : ''}`}
                                                        />
                                                        <ErrorMessage name="celular" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className='mb-1 '>
                                                            <strong>Email</strong>
                                                        </label>
                                                        <Field
                                                            type="email"
                                                            name="email"
                                                            className={`form-control ${touched?.email ? (errors.email ? 'is-invalid' : 'is-valid') : ''}`}

                                                        />
                                                        <ErrorMessage name="email" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                                    </div>
                                                    <div className='form-group'>
                                                        <label className='mb-1 '>
                                                            <strong>Password</strong>
                                                        </label>
                                                        <Field
                                                            type="password"
                                                            name="senha"
                                                            className={`form-control ${touched?.senha ? (errors.senha ? 'is-invalid' : 'is-valid') : ''}`}

                                                        />
                                                        <ErrorMessage name="senha" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                                    </div>
                                                    {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                                    <div className='text-center mt-4'>
                                                        <input type='submit' className='btn btn-primary btn-block' />
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                        <div className='new-account mt-3 '>
                                            <p>
                                                Ja tem uma conta?{' '}
                                                <Link className='text-primary' to='/login'>
                                                    Entrar
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);
