//import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Row, Col } from 'react-bootstrap'
import EmpresaConfiguracaoForm from "../../../components/Empresa/EmpresaConfiguracaoForm";
import EmpresaForm from "../../../components/Empresa/EmpresaForm";
//import ProcedimentosForm from "../../../components/Empresa/ProcedimentoForm";
import ProcedimentoList from "../../../components/Empresa/ProcedimentoList";
import Salas from "../../../components/Empresa/Salas";
import Feriados from "../../../components/Empresa/Feriados";
import Eventos from "../../../components/Empresa/Eventos";
import Convenios from "../../../components/Empresa/Convenios";
import SecaoList from "../../../components/Empresa/Secao/SecaoList";

const EmpresaAdd = () => {

   return (
      <>
         <div className="h-70 ms-0">

            <div className="">
               <div className="card">
                  <div className="card-body">
                     <Row>
                        <Tab.Container defaultActiveKey='1'>
                           <Col xl={3}>
                              <Nav as='ul' className='flex-column nav-pills mb-3'>

                                 <Nav.Item as='li' key="1">
                                    <Nav.Link eventKey="1">
                                       Empresa
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="2">
                                    <Nav.Link eventKey="2">
                                       Configurações
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="3">
                                    <Nav.Link eventKey="3">
                                       Procedimentos
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="4">
                                    <Nav.Link eventKey="4">
                                       Salas
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="5">
                                    <Nav.Link eventKey="5">
                                       Feriados
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="6">
                                    <Nav.Link eventKey="6">
                                       Eventos
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="7">
                                    <Nav.Link eventKey="7">
                                       Convenios
                                    </Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as='li' key="8">
                                    <Nav.Link eventKey="8">
                                       Seções
                                    </Nav.Link>
                                 </Nav.Item>

                              </Nav>
                           </Col>
                           <Col xl={9}>
                              <Tab.Content className='ms-2'>
                                 <Tab.Pane eventKey='1' key='1'>
                                    <EmpresaForm id={1} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='2' key='2'>
                                    <EmpresaConfiguracaoForm  />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='3' key='3'>
                                    <ProcedimentoList />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='4' key='4'>
                                    <Salas />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='5' key='5'>
                                    <Feriados />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='6' key='6'>
                                    <Eventos />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='7' key='7'>
                                    <Convenios />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey='8' key='8'>
                                    <SecaoList />
                                 </Tab.Pane>

                              </Tab.Content>
                           </Col>
                        </Tab.Container>
                     </Row>


                  </div>


               </div>
            </div>
         </div>
      </>
   );
};

export default EmpresaAdd;
