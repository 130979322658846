import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import InputMask from 'react-input-mask';
import { EmpresaValidationSchema, initialValues } from "../../models/Empresa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { estadosService } from "../../services/EstadosService";
import EmpresaService from "../../services/EmpresaService";
import SessionService from "../../services/SessionService";



const EmpresaForm = (id) => {
  const [formValues, setFormValues] = useState({})

  useEffect(() => {
    setFormValues(initialValues);
    const loadForm = async () => {
      if (id !== null) {
        try {
          const data = await EmpresaService.getEmpresaById(id.id);
          setFormValues(data);
        } catch (error) {
          console.error('Erro ao obter configuração da empresa:', error);
        }
      }
    };

    loadForm();
  }, []);

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const empresa  = values;
    empresa.cep = values.cep.replace(/[.,()-]/g, "");
  //  empresa.celular = values.celular.replace(/[.,()-]/g, "");
    empresa.telefone = values.telefone.replace(/[.,()-]/g, "");
    empresa.cpf_cnpj = values.cpf_cnpj.replace(/[.,()-]/g, "");
    const user = SessionService.getUser();
    if(empresa.profissional_id == null){
      empresa.profissional_id = user?.profissional?.id;
    }

    EmpresaService.createEmpresa(empresa).then(data => {
      toast.success("Salvo com sucesso", {
        position: "top-right",
        autoClose: 5000
      });
    }).catch(error => {
      if (error?.errors) {
        setErrors(error.errors);
        toast.warning(error.message, {
          position: "top-right",
          autoClose: 5000
        });
      }
    })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const EstadosBrasileiros = estadosService;
  const FileInput = ({ field, form, ...props }) => {
    // const [file, setFile] = useState(null);

    const handleChange = (event) => {
      const selectedFile = event.currentTarget.files[0];
      // setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        form.setFieldValue(field.name, reader.result);
      };
      reader.readAsDataURL(selectedFile);
    };

    return (
      <input type="file" onChange={handleChange} {...props} />
    );
  };

  return (
    <Fragment>
      <div className="basic-form">
        <Formik
          enableReinitialize={true}
          initialValues={formValues}
          validationSchema={EmpresaValidationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              handleSubmit(values, { setSubmitting, setErrors });
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            isSubmitting,
          }) => (


            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className={`form-group mb-1 col-lg-4 ${values.tipo ? (errors.tipo ? 'is-invalid' : 'is-valid') : ''}`}>
                  <label className="text-label">Tipo</label>
                  <Field
                    as="select"
                    className="form-control form-control-sm"
                    id="val-tipo"
                    name="tipo"
                  >
                    <option value="PF">Pessoa Física</option>
                    <option value="PJ">Pessoa Jurídica</option>
                  </Field>
                  <ErrorMessage name="tipo" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className={`form-group mb-1 col-lg-8 ${values.nome ? (errors.nome ? 'is-invalid' : 'is-valid') : ''
                  }`}>
                  <label className="text-label">Nome</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-nome"
                    placeholder="Digite um nome.."
                    name="nome"
                  />
                  <ErrorMessage name="nome" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className={`form-group mb-1 col-lg-6`} >
                  <label className="text-label">Email</label>
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${values.email ? (errors.email ? 'is-invalid' : 'is-valid') : ''}`}
                    id="val-email"
                    placeholder="Digite um email.."
                    name="email"
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className={`form-group mb-1 col-lg-6`}>
                  <label className="text-label">Telefone</label>
                  <Field
                    id="val-telefone"
                    placeholder="Digite um telefone.."
                    name="telefone"
                  >
                    {({ field }) => (
                      <InputMask
                        mask="(99) 99999-9999"
                        {...field}
                        className={`form-control form-control-sm ${field.value ? (
                          field.value.replace(/_/g, '').length === 15
                            ? 'is-valid'
                            : 'is-invalid') : ''
                          }`}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="telefone" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Razão Social</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-razao_social"
                    placeholder="Digite uma razão social.."
                    name="razao_social"
                  />
                  <ErrorMessage name="razao_social" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">

                  <label className="text-label">CPF/CNPJ</label>
                  <Field name="cpf_cnpj">
                    {({ field }) => (
                      <InputMask
                        mask={values.tipo === 'PF' ? '999.999.999-99' : '99.999.999/9999-99'}
                        {...field}
                        className={`form-control form-control-sm ${field.value} ${field.value &&
                          field.value.replace(/[^\d]/g, '').length === (field.value.length <= 14 ? 11 : 14)
                          ? 'is-valid'
                          : 'is-invalid'
                          }`}
                        id="val-cpf_cnpj"
                        placeholder="Digite um  CPF ou CNPJ.."
                        name="cpf_cnpj"

                      />
                    )}
                  </Field>
                  <ErrorMessage name="cpf_cnpj" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Inscrição Municipal</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-inscricao_municipal"
                    placeholder="Digite uma inscrição municipal.."
                    name="inscricao_municipal"
                  />
                  <ErrorMessage name="inscricao_municipal" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">CNES</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-cnes"
                    placeholder="Digite um CNES.."
                    name="cnes"
                  />
                  <ErrorMessage name="cnes" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">CEP</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-cep"
                    placeholder="Digite um CEP.."
                    name="cep"
                  >
                    {({ field }) => (
                      <InputMask
                        mask="99999-999"
                        {...field}
                        className={`form-control form-control-sm ${field.value ? (
                          field.value.replace(/_/g, '').length === 9
                            ? 'is-valid'
                            : 'is-invalid') : ''
                          }`}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="cep" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Logradouro</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-logradouro"
                    placeholder="Digite um endereço .."
                    name="logradouro"
                  />
                  <ErrorMessage name="logradouro" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Complemento</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-complemento"
                    placeholder="Digite um complemento.."
                    name="complemento"
                  />
                  <ErrorMessage name="complemento" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Bairro</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-bairro"
                    placeholder="Digite um bairro.."
                    name="bairro"
                  />
                  <ErrorMessage name="bairro" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-4">
                  <label className="text-label">Estado</label>
                  <Field
                    as="select"
                    name="estado"
                    className="form-control form-control-sm"
                  >
                    <option value="" disabled>Selecione um estado</option>
                    {EstadosBrasileiros.map((estado) => (
                      <option key={estado.sigla} value={estado.sigla}>
                        {estado.descricao}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="estado" component="div" className="invalid-feedback animated fadeInUp" />
                </div>

                <div className="form-group mb-1 col-lg-8">
                  <label className="text-label">Cidade</label>
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    id="val-cidade"
                    placeholder="Escolha uma cidade.."
                    name="cidade"
                  />
                  <ErrorMessage name="cidade" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-1 col-lg-6">
                  <label className="text-label">Foto</label>
                  <Field
                    name="foto"
                    component={FileInput}
                    accept="image/*"
                    className={`form-control form-control-sm`}
                  />
                  <ErrorMessage name="foto" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

              </div>
              <button
                type="submit"
                className="btn me-2 btn-primary"
              >
                Salvar
              </button>
              {/* <button className="btn btn-danger light">
                  Cancel
                </button> */}
            </form>
          )}
        </Formik>
      </div>

    </Fragment>
  );
};

export default EmpresaForm;
