import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class EventoService {

  async getEventos(params) {

    const cacheKey = params? `eventos-${params?.nome}`: `eventos`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/eventos`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getEventoById(id) {
    const cacheKey = `eventos-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/eventos/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createEvento(data) {
    SessionService.removeAll(`eventos`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/eventos', data);
      return response.data;
    } else {
      return await this.updateEvento(data.id, data);
    }

  }

  async updateEvento(id, data) {
    SessionService.removeAll(`eventos`);
    const response = await axiosInstance2.put(`/eventos/${id}`, data);
    return response.data;

  }


  async deleteEvento(id) {
    SessionService.removeAll(`eventos`);
    const response = await axiosInstance2.delete(`/eventos/${id}`);
    return response.data;

  }
}

export default new EventoService();
