import React, { Component, Fragment } from "react";
import { Col, Row, Card, Modal, Fade, ListGroup, Button} from "react-bootstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import moment from 'moment';
import Agenda from "../../../../components/Agenda/Agenda";
import { initialValuesAgenda } from "../../../../models/Agenda";
import AgendaService from "../../../../services/AgendaService";
import FeriadoService from "../../../../services/FeriadoService";
import EventoService from "../../../../services/EventoService";
import ProfissionalSecretariaService from "../../../../services/ProfissionalSecretariaService";
import EmpresaConfiguracaoService from "../../../../services/EmpresaConfiguracaoService";
import { toast } from "react-toastify";
import swal from "sweetalert";
import SessionService from "../../../../services/SessionService";

class EventCalendar extends Component {
   constructor(props) {
    super(props);

 
    
    this.state = { show: false };
    this.state = {
      calendarEvents: []
    };

  }
  
  // handleViewChange = (e) => {
  //   this.setState({ view: e.target.value });
  // };

  
  profissionalSelect = () => {
    if(this.state.profissionais && this.state.profissionais.length > 1){
      this.setState({ profissionalModal: true });
    }

  };
  alterarProfissional = (profissional) =>{
    let params = { profissional_id: profissional.id, sala_id: 1, exibir_todos_status: 1, visao: 'semanal'};
    this.setState({profissional: profissional.nome, 
      parametros: params
    });
    this.handleDatesSet(this.calendarInstance.calendar, params);
    this.calendarInstance.calendar.refetchEvents(); 
    this.profissionalModalShow(false);

  }
  profissionalModalShow(param){
    if(this.state.profissionais && this.state.profissionais.length > 1){
      this.setState({ profissionalModal: param });
    }
    
  }
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  tratarObjetoAgenda = (result) => {
    const data = moment(result.data, "YYYY-MM-DD").format("YYYY-MM-DD");
    const hora_ini = new Date(data + 'T' + result.hora_ini);
    const hora_fim = new Date(data + 'T' + result.hora_fim);
    return { title: result.nome, start: hora_ini, end: hora_fim, agenda: result, backgroundColor: result.cor };
  }
  feriadoParaAgenda = (feriado) => {
    const data = moment(feriado.data, "YYYY-MM-DD").format("YYYY-MM-DD");
    const hora_ini = new Date(data + 'T00:00:00');
    const hora_fim = new Date(data + 'T23:59:00');
    return { title: feriado.nome, start: hora_ini, end: hora_fim, backgroundColor: "#ff2d55" };
  }
  eventoParaAgenda = (evento) => {
    const data_ini = moment(evento.data_ini, "YYYY-MM-DD").format("YYYY-MM-DD");
    const data_fim = moment(evento.data_fim, "YYYY-MM-DD").format("YYYY-MM-DD");

    const hora_ini = new Date(data_ini + 'T' + evento.hora_ini);
    const hora_fim = new Date(data_fim + 'T' + evento.hora_fim);
    return { title: evento.nome, start: hora_ini, end: hora_fim, backgroundColor: evento.cor };
  }

 componentDidMount() {
    //  const calendarApi = this.calendarComponentRef;
    // const startDate = info.view.activeStart;
    // const endDate = info.view.activeEnd;
    const user = SessionService.getUser();

    //TODO: isso ta travando o sistema
     EmpresaConfiguracaoService.getEmpresaConfiguracaos().then(c => {
      // c[0].duracao_atendimento = 30; 
       this.setState({ configuracao: c[0] });
      //  this.state.configuracao = c[0];
     });
   

    if(user.tipo_usuario ==="Profissional"){
      this.setState({profissional: user.nome, parametros: {
        profissional_id: user.id, sala_id: 1, exibir_todos_status: 1, visao: 'semanal'
      }});
    }else{
        ProfissionalSecretariaService.getProfissionalSecretarias({ ativo: true }).then(response => {
          this.setState({profissionais: response.map(m => m.pessoa_profissional)});
          this.setState({profissional: response[0].pessoa_profissional.profissional.tratamento+'  '+response[0].pessoa_profissional.nome, parametros:{
            profissional_id: response[0].pessoa_profissional.id, sala_id: 1, exibir_todos_status: 1, visao: 'semanal'
          }});
        });

    }



  }

  handleEventDrop = async ({ event, revert }) => {
    swal({
      title: "Confirma a alteração?",
      text: "",
      icon: "warning",
      buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
      dangerMode: true,

    }).then(async (willDelete) => {
      if (willDelete) {
        let agenda = await AgendaService.getAgendaById(event.extendedProps.agenda.id);
        delete agenda.agenda_tipo;
        delete agenda.convenio;
        agenda.data = moment(event.start, "YYYY-MM-DD").format("YYYY-MM-DD");
        agenda.hora_ini = moment(event.start).format("HH:mm");
        agenda.hora_fim = moment(this.addMinutesToDate(event.startStr, 30)).format("HH:mm");
        AgendaService.createAgenda(agenda).then(_ => {
          toast.success("Agenda alterada com sucesso", {
            position: "top-right",
            autoClose: 5000,
          });

        }, error => {
          revert();
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      }
    })



    //   this.setState({ event: updatedEvents });
    //  setEvents(updatedEvents);
  };

  addMinutesToDate = (dateString, minutes) => {
    return new Date(moment(dateString)
      .add(minutes, 'minutes'));
  };
  handleDateClick = (eventClick) => {
    const newEvent = initialValuesAgenda;
    newEvent.nome = eventClick.title;
    newEvent.data = moment(eventClick.date, "YYYY-MM-DD").format("YYYY-MM-DD");
    newEvent.hora_ini = moment(eventClick.date).format("HH:mm");
    newEvent.hora_fim = moment(this.addMinutesToDate(eventClick.dateStr, this.state.configuracao.duracao_atendimento || 30)).format("HH:mm");
    this.setState({ event: newEvent });
    this.showModal();
  }
  handleDatesSet(dateInfo, parametros) {
    let params = parametros == null ? this.state.parametros : parametros;
    const startDate = moment(dateInfo.view.activeStart, "YYYY-MM-DD").format("YYYY-MM-DD");
    const endDate = moment(dateInfo.view.activeEnd, "YYYY-MM-DD").format("YYYY-MM-DD");
    let calendarEvents = [];
   
      AgendaService.getAgenda({
        ...params,  data_inicio: startDate, data_fim: endDate
      }).then(data => {
        // this.setState({ calendarEvents: data.map(m => this.tratarObjetoAgenda(m)) });
        calendarEvents = data.map(m => this.tratarObjetoAgenda(m));
        FeriadoService.getFeriados({}).then(feriado => {
          feriado.forEach(f => {
            calendarEvents.push(this.feriadoParaAgenda(f));
          });
          EventoService.getEventos({}).then(evento => {
            evento.forEach(f => {
              calendarEvents.push(this.eventoParaAgenda(f));
            });
            this.setState({ calendarEvents: calendarEvents });
          });
        });
      });


  }
  eventClick = async (eventClick) => {
    const response = await AgendaService.getAgendaById(eventClick.event.extendedProps.agenda.id);
    delete response.agenda_tipo;
    response.procedimentos = response.agenda_procedimentos.map(m => {
      return { procedimento_id: m.procedimento_id, valor: m.valor, qtde: m.qtde };
    });
    const newEvent = response; // eventClick.event.extendedProps.agenda;
    newEvent.data = moment(newEvent.data, "YYYY-MM-DD").format("YYYY-MM-DD");
    this.setState({ event: newEvent });
    this.showModal();

  };
  // handleDateClick = (info) => {
  //    Alert.fire("Deleted!", "Your Event has been deleted.", "success");
  //    console.log(info);
  //    if (info.view.type === 'timeGridWeek') {
  //      info.view.calendar.changeView('timeGridDay', info.dateStr);
  //    }
  //  };

  render() {
    return (
      <div className="demo-app">
        {this.state.show}
        {this.state.show == true ? <Agenda agendaInicial={this.state.event} state={this.state} onHide={this.hideModal} /> : ''}
{(this.state.parametros ) && (
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <div className="demo-app-calendar" id="mycalendartest">
                  <FullCalendar
                    locale="pt-br"
                    slotLabelInterval="01:00"
                     slotMinTime={this.state.configuracao?.hora_ini_agenda || "05:00:00"}
                     slotMaxTime={this.state.configuracao?.hora_fim_agenda || "19:00:00"}
                     slotDuration={this.state.configuracao?.duracao_atendimento || "00:30:00"}
                  //  slotMinTime={"05:00:00"}
                   // slotMaxTime={"19:00:00"}
                   // slotDuration={"00:30:00"}
                    allDaySlot={false}
                    displayEventTime={false}
                    selectable={true}
                    // views={{
                    //    timeGridWeek: {
                    //      type: 'timeGrid',
                    //      duration: { weeks: 1 },
                    //      slotMinTime: '07:00:00',
                    //      slotMaxTime: '18:00:00'
                    //    },
                    //    dayGridDay: {
                    //      type: 'dayGrid',
                    //      duration: { days: 1 }
                    //    }}}
                    customButtons={
                      {
                      viewSelect: {
                        text: this.state.profissional, 
                        click: this.profissionalSelect.bind(this) 
                      }
                    }}
                    buttonText={{
                      today: 'Hoje',
                      month: 'Mês',
                      week: 'Semana',
                      day: 'Dia',
                    }}
                    slotLabelFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      omitZeroMinute: false,
                      meridiem: 'short'
                    }}
                    headerToolbar={{
                      left: 'prev,next today viewSelect',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    defaultView={this.state.configuracao?.visualizacao_agenda || "timeGridWeek"}
                    rerenderDelay={10}
                    eventDurationEditable={false}
                    editable={true}
                    droppable={false}
                    plugins={[
                      dayGridPlugin,
                      listPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                    ]}
                    initialView={this.state.configuracao?.visualizacao_agenda || "timeGridWeek"}
                 //   ref={this.calendarComponentRef}
                    ref={(calendarInstance) => this.calendarInstance = calendarInstance}
                    weekends={this.state.calendarWeekends}
                    events={this.state.calendarEvents}
                    eventDrop={this.handleEventDrop}
                    //    eventReceive={this.eventReceive}
                    eventClick={this.eventClick}
                    navLinks={true}
                    dateClick={this.handleDateClick}
                    datesSet={(dateInfo) => this.handleDatesSet(dateInfo)}

                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        )}
        <Modal className="fade" show={this.state.profissionalModal} onHide={!this.state.profissionalModal}>
                  <Modal.Header>
                    <Modal.Title>Selecione um profissional</Modal.Title>
                    <Button
                      variant=""
                      className="btn-close"
                      onClick={() => this.profissionalModalShow(false)}
                    >
                      
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="basic-list-group">
                        <ListGroup as="ul">
                           {this.state.profissionais && (this.state.profissionais.map((list, i) => (
                              <Button key={i} variant="info  light" className="m-2 p-2" onClick={() => this.alterarProfissional(list)}>
                                 {list.profissional.tratamento}&nbsp;{list.nome}
                              </Button>
                           )))}
                        </ListGroup>
                     </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                    onClick={() => this.profissionalModalShow(false)}
                      variant="danger"
                    >
                      Cancelar
                    </Button>
                  </Modal.Footer>
                </Modal>
      </div>
    );
  }
}

export default EventCalendar;
