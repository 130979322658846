import React, { useState, useEffect } from "react";

import {  Button, Col, Modal, Row, Table } from "react-bootstrap";
import SecaosForm from "./SecaoForm";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import SecaoService from "../../../services/SecaoService";

const SecaoList = () => {
    const [data, setData] = useState([]);
    const [secaoId, setSecaoId] = useState(null);
    const [secaoOpen, setSecaoOpen] = useState(false);
    // const [filter, setFilter] = useState(null);
    const loadData = async () => {
        const data = await SecaoService.getSecoes({});
        setData(data);
    }

    const remover = (secaoId) => {
        SecaoService.deleteSecao(secaoId).then(() => {
            loadData();
            swal("Registro removido com sucesso!", {
                icon: "success",
            });

        })
    }

    const salvar = () => {
        loadData();
        setSecaoOpen(false);
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="form-head d-flex mb-1 md-2 align-items-start">
                        <div className="me-auto d-none d-lg-block">
                            <Button onClick={() => { setSecaoOpen(true); setSecaoId(null) }}
                                className="btn btn-primary btn-rounded"
                            >
                                + Novo
                            </Button>
                        </div>

                    </div>

                    <div className="card">
                        <div className="card-body">

                            <Table responsive bordered striped className="verticle-middle">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">
                                            Nome
                                        </th>
                                        <th scope="col" style={{ width: 120 }}>Ação</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((secao, i) => (
                                        <tr role="row" key={i}>
                                            <td >{secao.nome}</td>
                                            <td role="row" >
                                                <span>
                                                    <Link className="me-3" onClick={() => { setSecaoId(secao.id); setSecaoOpen(true); }}><i className="fa fa-pencil  color-muted"></i></Link>

                                                    <Link className="me-3" onClick={() =>
                                                        swal({
                                                            title: "Confirma a exclusão?",
                                                            text: "",
                                                            icon: "warning",
                                                            buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                            dangerMode: true,

                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                remover(secao.id)
                                                            }
                                                        })
                                                    } ><i className="fa fa-trash  text-danger"></i></Link>
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div
                                    className="dataTables_info"
                                    id="example5_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                     Showing {activePag.current * sort + 1} to{" "}
                                 {data.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : data.length}{" "}
                                 of {data.length} entries  
                                </div>

                            </div> */}
                        </div>
                    </div>

                </Col>
            </Row>

            <Modal
                className="fade"
                fullscreen={true}
                show={secaoOpen}
              //  dialogClassName="modal-60w"
            >
                <Modal.Header>
                    <Modal.Title>Secao</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setSecaoOpen(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <SecaosForm secao={{  id: secaoId }} onRetorno={salvar} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SecaoList;
