class SessionService {
    static getUser() {
        const userData = localStorage.getItem('userDetails');
        return userData ? JSON.parse(userData) : null;
    }

    static getToken() {
        return localStorage.getItem('token');
    }

    static login(userData, authToken) {
        localStorage.setItem('userDetails', JSON.stringify(userData));
        localStorage.setItem('token', authToken);
    }

    static logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }

    static addToCache(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static removeFromCache(key) {
        localStorage.removeItem(key);
    }

    static getCachedData(chave) {
        const cachedData = localStorage.getItem(chave);
        return cachedData ? JSON.parse(cachedData) : null;
    }
    static removeAll(substring) {
        const storedKeys = Object.keys(localStorage);

        for (const key of storedKeys) {
            if (key.includes(substring)) {
                SessionService.removeFromCache(key);
            }
        }


    }
}

export default SessionService;
