import React, { useState,  useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import SecretariaService from "../../../services/SecretariaService";
import SessionService from "../../../services/SessionService";
import ProfissionalSecretariaService from "../../../services/ProfissionalSecretariaService";

const SecretariaList = () => {
   const [secretarias, setSecretarias] = useState([]);
   const [params, setParams] = useState({
      page: 1,
      nome: ""
   });
   const usuarioSessao = SessionService.getUser();
   const navigate = useNavigate();

   const loadData = async () => {
      const secretariaRetorno = await SecretariaService.getSecretarias(params);
      setSecretarias(secretariaRetorno);
   };


   useEffect(() => {
      loadData();
   }, []);


   const remover = (id) => {
      SecretariaService.deleteSecretaria(id).then(() => {
         loadData();
         swal("Registro removido com sucesso!", {
            icon: "success",
         });

      })
   }
   const vincular = (secretaria_id, ativo, id) => {
      var dados = {
         id: id,
         secretaria_id: secretaria_id,
         profissional_id: usuarioSessao.id,
         ativo: !ativo,
         empresa_id: usuarioSessao.empresa_profissional.id
      };
   
      ProfissionalSecretariaService.createProfissionalSecretaria(dados).then(() => {
         loadData();
         swal("Registro salvo com sucesso!", {
            icon: "success",
         });

      })
   }

   return (
      <>
         <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
            <div className="me-auto d-none d-lg-block">
               <Link
                  to="/secretaria"
                  className="btn btn-primary btn-rounded"
               >
                  + Novo
               </Link>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="table-responsive table-striped">
                        <div
                           id="patient_list"
                           className="dataTables_wrapper no-footer"
                        >
                           <table
                              id="example5"
                              className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer"
                              role="grid"
                              aria-describedby="example5_info"
                           >
                              <thead>
                                 <tr role="row">
                                    <th>
                                       Nome
                                    </th>
                                    <th>
                                       Email
                                    </th>
                                    <th>
                                       Celular
                                    </th>
                                    <th>
                                       Nascimento
                                    </th>
                                    <th></th>

                                 </tr>
                              </thead>
                              <tbody>
                                 {secretarias && (secretarias.map(pessoa => (
                                    <tr role="row">
                                       <td>{pessoa.nome}</td>
                                       <td>{pessoa.email}</td>
                                       <td>{pessoa.celular}</td>
                                       <td>{pessoa.data_nascimento}</td>
                                       <td>
                                          <span className="me-1">
                                             <Button variant='primary tp-btn-light' onClick={() => { navigate(`/secretaria/${pessoa.id}`) }}><i className="fa fa-pencil fs-18 text-success"></i></Button>
                                          </span>
                                          <span className="me-1">
                                             <Button variant='danger tp-btn-light' onClick={() =>
                                                swal({
                                                   title: "Confirma a exclusão?",
                                                   text: "",
                                                   icon: "warning",
                                                   buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                   dangerMode: true,

                                                }).then((willDelete) => {
                                                   if (willDelete) {
                                                      remover(pessoa.id)
                                                   }
                                                })
                                             }><i className="fa fa-trash fs-18 text-danger"></i></Button>

                                          </span>
                                          {usuarioSessao.tipo_usuario ==="Profissional" && (
                                          <span className="me-1">
                                          <Button variant='info tp-btn-light' onClick={() =>
                                                swal({
                                                   title: pessoa.profissional_secretaria.ativo === true ? "Desvincular Secretária" : "Confirma o Vículo?",
                                                   text: "",
                                                   icon: "warning",
                                                   buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                   dangerMode: true,

                                                }).then((willDelete) => {
                                                   if (willDelete) {
                                                      vincular(pessoa.id, pessoa.profissional_secretaria.ativo, pessoa.profissional_secretaria.id)
                                                   }
                                                })
                                             }>{pessoa.profissional_secretaria && pessoa.profissional_secretaria.ativo && pessoa.profissional_secretaria.profissional_id ===usuarioSessao.id ? 'Desvincular' : 'Vincular'}
                                             </Button>
                                          </span>
                                          )}
                                          

                                       </td>
                                    </tr>
                                 )))}

                              </tbody>
                           </table>

                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </div>
      </>
   );
};

export default SecretariaList;
