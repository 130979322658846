import  { axiosInstanceChat } from '../services/AxiosInstanceChat';

class ChatService {

  async getChats(empresa_id) {

    const response = await axiosInstanceChat.get(`/chats/${empresa_id}`);
    return response.data;

  }


  async getChatById(id) {
   
    const response = await axiosInstanceChat.get(`/chat/${id}`);
    return response.data;

  }



}

export default new ChatService();
