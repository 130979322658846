//import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import PessoaForm from "../../../components/Pessoa/PessoaForm";

import {Tab, Nav } from 'react-bootstrap'
import ProfissionalForm from "../../../components/Pessoa/ProfissionalForm";

const PessoaPage = () => {


   return (
      <>
         <div className="h-70 ms-0">
            <div className="page-titles">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                     <Link to="/reviews">Pessoa</Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >Salvar</Link>
                  </li>
               </ol>
            </div>
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
               <div className="card">
                  <div className="card-body">
                     <Tab.Container defaultActiveKey='1'>
                        <Nav as='ul' className='nav-pills mb-4 light'>

                           <Nav.Item as='li' key="1">
                              <Nav.Link eventKey="1">
                                 Pessoa
                              </Nav.Link>
                           </Nav.Item>
                           <Nav.Item as='li' key="2">
                              <Nav.Link eventKey="2">
                                 Profissional
                              </Nav.Link>
                           </Nav.Item>
                           <Nav.Item as='li' key="3">
                              <Nav.Link eventKey="3">
                                 Paciente
                              </Nav.Link>
                           </Nav.Item>

                        </Nav>
                        <Tab.Content className='pt-4'>
                           <Tab.Pane eventKey='1' key='1'>
                           {/* <PessoaForm pessoa={{ tipo: 'Profissional', id: 1 }} />  */}
                           <PessoaForm pessoa={{ tipo: 'Secretaria'}} /> 
                           </Tab.Pane>
                           <Tab.Pane eventKey='2' key='2'>
                              <PessoaForm pessoa={{ tipo: 'Profissional'}} />
                           </Tab.Pane>
                           <Tab.Pane eventKey='3' key='3'>
                              <PessoaForm pessoa={{ tipo: 'Paciente'}} /> 
                           </Tab.Pane>

                        </Tab.Content>
                     </Tab.Container>



                  </div>


               </div>
            </div>
         </div>
      </>
   );
};

export default PessoaPage;
