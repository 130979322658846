import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import InteracaoService from "../../services/InteracaoService";
import { Badge,Stack } from "react-bootstrap";
import SecaoDetalhes from "./SecaoDetalhes";
import moment from "moment";
const Historico = (prontuario_id) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState([]);
  const loadData = async () => {
    const data = await InteracaoService.getInteracoes({ prontuario_id: prontuario_id });
    setData(data);
    setOpen( Array(data.length).fill(false));
  }
  const toggleOpen = (index) => {
    const newOpen = [...Array(open.length).fill(false)];
    newOpen[index] =true;
    setOpen(newOpen);
  };
  const formatTempoAtendimento = (tempoAtendimento) => {
    if(!tempoAtendimento) return "Em atendimento";
    const [horas, minutos] = tempoAtendimento.split(':').map(Number);
    const totalMinutos = horas * 60 + minutos;
  
    if (totalMinutos >= 60) {
      const horasFormatadas = Math.floor(totalMinutos / 60);
      const minutosFormatados = totalMinutos % 60;
      return `${horasFormatadas} hora${horasFormatadas > 1 ? 's' : ''} e ${minutosFormatados} minuto${minutosFormatados > 1 ? 's' : ''}`;
    } else {
      return `${totalMinutos} minuto${totalMinutos > 1 ? 's' : ''}`;
    }
  }
  useEffect(() => {
    loadData();
  }, []);
  return (
    <Fragment>
      <div className='card'>
        <div className='card-header border-0 pb-0'>
          <h4 className='card-title'>Histórico Clínico</h4>
        </div>
        <div className='card-body'>
          <div className='widget-timeline style-1' >
               
            <ul className='timeline'>
              {data && data.map((atendimento, i) => (                    
                <li key={i}  >
                  <div className='timeline-badge primary'></div>
                  
                  <Link
                    className='timeline-panel text-muted'
                    onClick={() =>  toggleOpen(i)} 
                  
                  >
                    
                    <span><strong>{moment(atendimento.data).format('DD/MM/YYYY')} </strong> <small>Tempo de consulta {formatTempoAtendimento(atendimento.tempo_atendimento)}</small></span>
                    <h6 className='mb-0'>
                      <Stack direction="horizontal" gap={2}>
                        {atendimento.interacao_atendimentos.map(m => (<Badge bg="info">{m.secao?.nome || "Seção removida"}</Badge>))}
                      </Stack>
                    </h6>
                    
                      {open[i] && atendimento.interacao_atendimentos && (
                        <SecaoDetalhes interacoes={atendimento.interacao_atendimentos}  />
                      )}
                  </Link>

                </li>
              ))}
 
            </ul>
          </div>
        </div>
      </div>

    </Fragment>
  )
}
export default Historico