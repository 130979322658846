import React, { useState, useEffect } from "react";
import ProcedimentoService from "../../services/ProcedimentoService";
import { Badge, Button, Col, Modal, Row, Table } from "react-bootstrap";
import ProcedimentosForm from "./ProcedimentoForm";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const ProcedimentoList = () => {
    const [data, setData] = useState([]);
    const [procedimentoId, setProcedimentoId] = useState(null);
    const [procedimentoOpen, setProcedimentoOpen] = useState(false);
    // const [filter, setFilter] = useState(null);
    const loadData = async () => {
        const data = await ProcedimentoService.getProcedimentos({});
        setData(data);
    }

    const remover = (procedimentoId) => {
        ProcedimentoService.deleteProcedimento(procedimentoId).then(() => {
            loadData();
            swal("Registro removido com sucesso!", {
                icon: "success",
            });

        })
    }

    const salvar = () => {
        loadData();
        setProcedimentoOpen(false);
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="form-head d-flex mb-1 md-2 align-items-start">
                        <div className="me-auto d-none d-lg-block">
                            <Button onClick={() => { setProcedimentoOpen(true); setProcedimentoId(null) }}
                                className="btn btn-primary btn-rounded"
                            >
                                + Novo
                            </Button>
                        </div>
                        {/* <div className="input-group search-area ms-auto d-inline-flex me-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar.."
                        value={filter}
                        onChange={(e) => {setFilter(e.target.value)}}
                    />
                    <div className="input-group-append">
                        <button type="button" className="input-group-text" onClick={() =>{ loadData(); }}>
                            <i className="flaticon-381-search-2" />
                        </button>
                    </div>
                </div> */}

                    </div>

                    <div className="card">
                        <div className="card-body">

                            <Table responsive bordered striped className="verticle-middle">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">
                                            Nome
                                        </th>
                                        <th scope="col">
                                            Tipo
                                        </th>
                                        <th scope="col">
                                            Duração
                                        </th>
                                        <th scope="col">
                                            Valor
                                        </th>
                                        <th scope="col">
                                            Cor
                                        </th>
                                        <th scope="col" style={{ width: 120 }}>Ação</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((procedimento, i) => (
                                        <tr role="row" key={i}>
                                            <td >{procedimento.nome}</td>
                                            <td>{procedimento?.procedimento_tipo?.nome}</td>
                                            <td>{procedimento.duracao_min}</td>
                                            <td>{procedimento.valor}</td>
                                            <td> <Badge bg="" className='light badge-xs' style={{ 'background': procedimento.cor }}>{procedimento.cor}</Badge></td>
                                            <td role="row" >

                                                <span>
                                                    <Link className="me-3" onClick={() => { setProcedimentoId(procedimento.id); setProcedimentoOpen(true); }}><i className="fa fa-pencil  color-muted"></i></Link>

                                                    <Link className="me-3" onClick={() =>
                                                        swal({
                                                            title: "Confirma a exclusão?",
                                                            text: "",
                                                            icon: "warning",
                                                            buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                            dangerMode: true,

                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                remover(procedimento.id)
                                                            }
                                                        })
                                                    } ><i className="fa fa-trash  text-danger"></i></Link>
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div
                                    className="dataTables_info"
                                    id="example5_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                     Showing {activePag.current * sort + 1} to{" "}
                                 {data.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : data.length}{" "}
                                 of {data.length} entries  
                                </div>

                            </div> */}
                        </div>
                    </div>

                </Col>
            </Row>

            <Modal
                className="fade"
                show={procedimentoOpen}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Procedimento</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setProcedimentoOpen(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <ProcedimentosForm procedimento={{  id: procedimentoId }} onRetorno={salvar} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProcedimentoList;
