import * as Yup from 'yup';
const initialValues = {
    id: null,
    nome: '',
    formulario: ''
  };

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres'),
   // formulario: Yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres')
  });

  export {initialValues,  validationSchema };