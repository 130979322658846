import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';
class ProfissaoService {

  async getProfissao() {
    const cacheKey = `profissoes`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
       return cachedData;
     }
      const response = await axiosInstance2.get('/profissoes');
      SessionService.addToCache(cacheKey, response.data);
      return response.data;

  }


  async getProfissaoById(id) {
      const response = await axiosInstance2.get(`/profissoes/${id}`);
      return response.data;

  }


  async createProfissao(profissionalData) {
    if(profissionalData.id == null){
      const response = await axiosInstance2.post('/profissoes', profissionalData);
      return response.data;
    }else{
        return  await this.updateProfissao(profissionalData.id, profissionalData);
    }

  }

  async updateProfissao(id, profissionalData) {
      const response = await axiosInstance2.put(`/profissoes/${id}`, profissionalData);
      return response.data;

  }

 
  async deleteProfissao(id) {
      const response = await axiosInstance2.delete(`/profissoes/${id}`);
      return response.data;

  }
}

export default new ProfissaoService();
