import React, { useState, useEffect } from "react";
import { PAGINATIO_PER_PAGE } from '../../../config';
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import avat1 from '../../../images/avatar/1.jpg';
import AgendaService from "../../../services/AgendaService";
import CustomPagination from "../Pagination/CustomPagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Button, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import ProfissionalService from "../../../services/ProfissionalService";
import ProfissionalSecretariaService from "../../../services/ProfissionalSecretariaService";

const Appointment = () => {
    const [consultas, setConsultas] = useState([]);
    const [consulta, setConsulta] = useState();
    const [profissionais, setProfissionais] = useState([]);
    
    const [params, setParams] = useState({
        per_page: PAGINATIO_PER_PAGE,
        page: 1,
        nome: "",
        data_inicio: moment().subtract(1, 'days').format("YYYY-MM-DD"),
        data_fim: moment().add(7, 'days').format("YYYY-MM-DD"),
        exibir_todos_status: 1,
        profissional_id: null,
        apenas_agenda: 0
    });

  //  const navigate = useNavigate();
  
    const loadData = async () => {
        try {
            const consultaRetorno = await AgendaService.getAgendaPacientes(params);
            setConsulta(consultaRetorno);
            setConsultas(consultaRetorno.data || []); // Se consultaRetorno.data não existir, usa um array vazio
        } catch (error) {
            console.error("Erro ao carregar dados:", error);
        }
    };

    const pageClick = (page) => {
        params.page = page;
        setParams(params);
        loadData();
    };

    const handleDateRangeChange = (event, picker) => {
        setParams({
          ...params,
          data_inicio: picker.startDate.format('YYYY-MM-DD'),
          data_fim: picker.endDate.format('YYYY-MM-DD'),
        });
      };
    const fetchProfissionais = async () => {
        const profissionaisRetorno = await ProfissionalSecretariaService.getProfissionalSecretarias({});
        setProfissionais(profissionaisRetorno.map(m => m.pessoa_profissional) || []);
        params.profissional_id = profissionaisRetorno[0]?.profissional_id || null;
        loadData();
    };
    useEffect(() => {
        fetchProfissionais();
       
    }, []);

    return (
        <>
            <div className="card ">
                <div className="card-body">
                    <div className="basic-form">
                        <Formik
                            initialValues={params}
                            onSubmit={async (values) => {
                                // Atualiza os parâmetros com os valores do formulário
                                setParams({
                                    ...params,
                                    nome: values.nome,
                                    data_inicio: moment(values.data_inicio).format("YYYY-MM-DD"),
                                    data_fim: moment(values.data_fim).format("YYYY-MM-DD"),
                                    profissional_id: values.profissional_id
                                });

                                // Carrega os dados da agenda
                                loadData();
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="mb-0 row">
                                        <div className="col-md-3">
                                        <p className="mb-1">Profissional</p>
                                            <Field name="profissional_id" as="select" className="form-control form-control-sm "
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                {profissionais.map((opcao, index) => (
                                                    <option key={index} value={opcao.id}>
                                                        {opcao.nome}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <div className="col-sm-4">
                                            <p className="mb-1">Paciente</p>
                                            <Field
                                                onBlur={handleBlur}
                                                type="text"
                                                name="nome"
                                                className="form-control form-control-sm"
                                                placeholder="Busca pelo nome do paciente"
                                            />
                                        </div>
                                        <div className="col-md-3 mb-1">
                                            <div className="example rangeDatePicker">
                                                <p className="mb-1">Data da consulta</p>
                                                <DateRangePicker
                                                    initialSettings={{
                                                        startDate: moment(params.data_inicio).format('MM/DD/YYYY'),
                                                        endDate: moment(params.data_fim).format('MM/DD/YYYY'),
                                                        locale: { format: 'MM/DD/YYYY' }
                                                    }}
                                                    onApply={handleDateRangeChange}
                                                    >
                                                    <input type="text" className="form-control input-daterange-timepicker form-control-sm"      
                                                    onChange={handleChange} onBlur={handleBlur}></input>
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                        <div className="col-md-2 pt-2">
                                            <Button variant='primary btn-sm mt-3' type="submit">Buscar</Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div id="appointment" className="dataTables_wrapper no-footer table-sm">
                            <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14 dataTable no-footer">
                                <thead>
                                    <tr role="row">
                                        <th>Nome</th>
                                        <th>Consulta</th>
                                        <th>Inicio</th>
                                        <th>Fim</th>
                                        <th>Celular</th>
                                        <th>Profissional</th>
                                        {/* <th>Injury/Condition</th> */}
                                        <th>Acão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {consultas && consultas.length > 0 && consultas.map((item, ind) => (
                                        <tr className="odd" key={ind}>
                                            <td className="patient-info ps-0">
                                                {<span>
                                                    <img src={avat1} alt="" />
                                                </span>}
                                                <span className="text-nowrap ms-2">{item.nome}</span>
                                            </td>
                                            <td>{moment(item.data).format('DD/MM/YYYY')}</td>
                                            <td>{item.hora_ini}</td>
                                            <td>{item.hora_fim}</td>
                                            <td className="text-primary">{item.celular}</td>
                                            <td>{item.profissional.nome} </td>
                                            {/* <td>Fever</td> */}
                                            <td>
                                                <span className="me-0">
                                                    <Link to={"/prontuario/" + item.prontuarios[0].id + "/"+item.id} className="btn btn-primary btn-sm">Histórico</Link>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {consulta && (
                                <CustomPagination
                                    totalPages={consulta.total}
                                    activePage={consulta.current_page}
                                    itemsPerPage={consulta.per_page}
                                    onPageClick={pageClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Appointment;