import React, { useState, useEffect } from "react";
import EventoService from "../../services/EventoService";
import InputMask from 'react-input-mask';
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import * as Yup from 'yup';
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import 'moment/locale/pt-br'
import DateRangePicker from "react-bootstrap-daterangepicker";
import EmpresaProfissionalService from "../../services/EmpresaProfissionalService";
import Select from "react-select";
moment.locale('pt-br');
const Eventos = () => {
    const [data, setData] = useState([]);
    const [eventoOpen, setEventoOpen] = useState(false);
    const [evento, setEvento] = useState(null);
    const [filter, setFilter] = useState(null);
    const loadData = async () => {
        const data = await EventoService.getEventos({nome: filter});
        setData(data);
    }
    const handleDateChange = (event, picker) => {
        const startDate = picker.startDate.format('DD/MM/YYYY');
        const endDate = picker.endDate.format('DD/MM/YYYY');
        evento.data_ini = startDate;
        evento.data_fim = endDate;
        setEvento(evento);
    };
    const [profissionaisOptions, setProfissionaisOptions] = useState([]);


    const handleProfissionaisChange = (selectedOptions, setFieldValue) => {
        setFieldValue('profissionais', selectedOptions);
    };
    const remover = (eventoId) => {
        EventoService.deleteEvento(eventoId).then(() => {
            loadData();
            swal("Registro removido com sucesso!", {
                icon: "success",
            });

        }).catch(error => {
            toast.error('Problemas ao remover esse registro, verifique se ele não está sendo utilizado em algum outro lugar.', {
                position: "top-right",
                autoClose: 5000
            });
        });
    }

    const editar = (data) => {
        setEventoOpen(true);
        setEvento(data);
    }

    const novo = () => {
        setEvento({
            id: null,
            nome: "",
            data_ini: moment().format('DD/MM/YYYY'),
            data_fim: moment().add(1, 'days').format('DD/MM/YYYY'),
            descricao: "",
            hora_ini: "",
            hora_fim: "",
            dias_semana: "",
            cor: "#A336C9",
            profissionais: []
        });
        setEventoOpen(true);
    }

    const salvar = (values, { setSubmitting, setErrors }) => {
        const evento = values;
        evento.profissionais = evento.profissionais.map(m => m.value);
        EventoService.createEvento(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            setEventoOpen(false);
            loadData();
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    useEffect(() => {
        EmpresaProfissionalService.getEmpresaProfissionais().then(filteredProfissionais => {
            setProfissionaisOptions(filteredProfissionais);
        });
        loadData();
    }, []);
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="form-head d-flex mb-1 md-2 align-items-start">
                        <div className="me-auto d-none d-lg-block">
                            <Button onClick={() => novo()}
                                className="btn btn-primary btn-rounded"
                            >
                                + Novo
                            </Button>
                        </div>
                        <div className="input-group search-area ms-auto d-inline-flex me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar.."
                                value={filter}
                                onChange={(e) => { setFilter(e.target.value) }}
                            />
                            <div className="input-group-append">
                                <button type="button" className="input-group-text" onClick={() => { loadData(); }}>
                                    <i className="flaticon-381-search-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">

                            <Table responsive bordered striped className="verticle-middle">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">
                                            Data
                                        </th>
                                        <th scope="col">
                                            Nome
                                        </th>
                                        <th scope="col">
                                            Semana
                                        </th>
                                        <th scope="col" style={{ width: 120 }}>Ação</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((evento, i) => (
                                        <tr role="row" key={i}>
                                            <td >{moment(evento.data_ini).format('DD/MM/YYYY')} {evento.hora_ini} - {moment(evento.data_fim).format('DD/MM/YYYY')} {evento.hora_fim}</td>
                                            <td >{evento.nome}</td>
                                            <td> {evento.semana} {moment(evento.data_ini).format('dddd')}</td>
                                            <td role="row" >

                                                <span>
                                                    <Link className="me-3" onClick={() => { editar(evento) }}><i className="fa fa-pencil  color-muted"></i></Link>

                                                    <Link className="me-3" onClick={() =>
                                                        swal({
                                                            title: "Confirma a exclusão?",
                                                            text: "",
                                                            icon: "warning",
                                                            buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                            dangerMode: true,

                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                remover(evento.id)
                                                            }
                                                        })
                                                    } ><i className="fa fa-trash  text-danger"></i></Link>
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Col>
            </Row>

            <Modal
                className="fade"
                show={eventoOpen}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Evento</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setEventoOpen(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        enableReinitialize={true}
                        initialValues={evento}
                        validationSchema={Yup.object().shape({
                            nome: Yup.string().required('Campo obrigatório'),
                            data_ini: Yup.string().required('Campo obrigatório'),
                            data_fim: Yup.string().required('Campo obrigatório'),
                            hora_ini: Yup.string().required('Campo obrigatório'),
                            hora_fim: Yup.string().required('Campo obrigatório'),
                            dias_semana: Yup.string().required('Campo obrigatório'),
                            cor: Yup.string().required('Campo obrigatório'),
                            profissionais: Yup.array().min(1, 'A lista tem ter ao menos 1 item').required('Campo Obrigatório')

                        })
                        }
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            salvar(values, { setSubmitting, setErrors });
                        }}
                    >
                        {({ touched, errors, values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className="form-group mb-1 col-lg-6">
                                        <label className="text-label">Data</label>
                                        <DateRangePicker
                                            initialSettings={{ startDate: values.data_ini, endDate: values.data_fim }}
                                            onApply={handleDateChange}
                                        >
                                            <input type="text" className="form-control  form-control-sm input-daterange-timepicker" />
                                        </DateRangePicker>
                                        <ErrorMessage name="data_ini" component="div" className="invalid-feedback" />
                                        <ErrorMessage name="data_fim" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-3">
                                        <label className="text-label">Hora de Início</label>
                                        <Field
                                            type="text"
                                            name="hora_ini"
                                            className={`form-control  form-control-sm ${touched.hora_ini && errors.hora_ini ? 'is-invalid' : 'is-valid'}`}

                                            as={InputMask}
                                            mask="99:99"
                                        />
                                        <ErrorMessage name="hora_ini" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                    </div>
                                    <div className="form-group mb-1 col-lg-3">
                                        <label className="text-label">Hora de Fim</label>
                                        <Field
                                            type="text"
                                            name="hora_fim"
                                            className={`form-control  form-control-sm ${touched.hora_fim && errors.hora_fim ? 'is-invalid' : 'is-valid'}`}

                                            as={InputMask}
                                            mask="99:99"
                                        />
                                        <ErrorMessage name="hora_fim" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                    </div>
                                    <div className="form-group mb-1 col-lg-12">
                                        <label className="text-label">Nome</label>
                                        <Field
                                            type="text"
                                            name="nome"
                                            className={`form-control  form-control-sm ${touched.nome && errors.nome ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="nome" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-12">
                                        <label className="text-label">Descrição</label>
                                        <Field
                                            type="text"
                                            name="descricao"
                                            className={`form-control  form-control-sm ${touched.descricao && errors.descricao ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="descricao" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-6">
                                        <label className="text-label">Dias Semana</label>
                                        <Field
                                            as="select"
                                            className={`form-control  form-control-sm ${touched.dias_semana && errors.dias_semana ? 'is-invalid' : 'is-valid'}`}
                                            id="dias-semana"
                                            name="dias_semana"
                                        >
                                            <option value="1">domingo</option>
                                            <option value="2">segunda</option>
                                            <option value="3">terça</option>
                                            <option value="4">quarta</option>
                                            <option value="5">quinta</option>
                                            <option value="6">sexta</option>
                                            <option value="7">sabado</option>
                                        </Field>
                            
                                        <ErrorMessage name="dias_semana" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-6">
                                        <label className="text-label">Cor</label>
                                        <Field
                                            type="color"
                                            name="cor"
                                            className={`form-control  form-control-sm ${touched.cor && errors.cor ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="cor" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-12">
                                        <label className="text-label">Profissionais</label>
                                        <Select

                                            name="profissionais"
                                            options={profissionaisOptions.map((profissional) => ({
                                                value: profissional.profissional.id,
                                                label: profissional.profissional.nome,
                                            }))}
                                            className={` ${touched?.profissionais ? (errors?.profissionais ? 'is-invalid' : 'is-valid') : ''}`}
                                            isMulti
                                            onChange={(selectedOptions) => handleProfissionaisChange(selectedOptions, setFieldValue)}
                                            value={values?.profissionais && values?.profissionais.map(item => ({
                                                value: item.pessoa.nome,
                                                label: item.pessoa.id

                                            }))}

                                        />
                                        <ErrorMessage name="profissionais" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                    </div>


                                </div>
                                {/* Continue com os campos restantes do formulário, seguindo o mesmo padrão */}

                                <div className="form-head d-flex mb-1 md-1 align-items-start">
                                    <button type="submit" className="btn btn-primary btn-sm ms-auto d-inline-flex me-1">Salvar</button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default Eventos;
