import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class SecaoService {

  async getSecoes(params) {

    const cacheKey = `secoes`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
   }
    const response = await axiosInstance2.get(`/secoes`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getSecaoById(id) {
    const cacheKey = `secoes-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/secoes/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createSecao(data) {
    SessionService.removeAll(`secoes`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/secoes', data);
      return response.data;
    } else {
      return await this.updateSecao(data.id, data);
    }

  }

  async updateSecao(id, data) {
    SessionService.removeAll(`secoes`);
    const response = await axiosInstance2.put(`/secoes/${id}`, data);
    return response.data;

  }


  async deleteSecao(id) {
    SessionService.removeAll(`secoes`);
    const response = await axiosInstance2.delete(`/secoes/${id}`);
    return response.data;

  }
}

export default new SecaoService();
