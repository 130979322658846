import { axiosInstance2 } from '../services/AxiosInstance2';

class ProfissionalSecretariaService {


  async getProfissionalSecretarias(params) {
    const response = await axiosInstance2.get('/profissional-secretarias', { params });
    return response.data;

  }



  async getProfissionalSecretariaById(id) {
    const response = await axiosInstance2.get(`/profissional-secretarias/${id}`);
    return response.data;

  }


  async createProfissionalSecretaria(profissionalData) {
    if (profissionalData.id == null) {
      const response = await axiosInstance2.post('/profissional-secretarias', profissionalData);
      return response.data;
    } else {
      return await this.updateProfissionalSecretaria(profissionalData.id, profissionalData);
    }

  }

  async updateProfissionalSecretaria(id, profissionalData) {
    const response = await axiosInstance2.put(`/profissional-secretarias/${id}`, profissionalData);
    return response.data;

  }


  async deleteProfissionalSecretaria(id) {
    const response = await axiosInstance2.delete(`/profissional-secretarias/${id}`);
    return response.data;

  }

}

export default new ProfissionalSecretariaService();