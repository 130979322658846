import { useParams } from "react-router-dom";
import ProntuarioForm from "../../../components/Prontuario/ProntuarioForm";

const ProntuarioPage = () => {
    const { id, agenda_id } = useParams();


    return (
        <ProntuarioForm id={id} agenda_id={agenda_id} />
    );
}
export default ProntuarioPage;