import React from "react";

import EventCalendar from "./EventCalendar";

//import PageTitle from "../../../layouts/PageTitle";

const Calendar = () => {
   return (
      <div className="h-40">
         {/* <PageTitle activeMenu="Agendamentos" motherMenu="App" /> */}
         <EventCalendar />
      </div>
   );
};

export default Calendar;
