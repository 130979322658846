import React, { useState, useEffect, Fragment } from 'react';
import SecaoService from "../../../services/SecaoService";
import { validationSchema, initialValues } from '../../../models/Secao';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import MontarJsonInput from './MontarJsonInput';
import { Card } from 'react-bootstrap';


const SecaoForm = ({ secao, onRetorno }) => {
    const [formValues, setFormValues] = useState({ nome: '', formulario: '' });
    const [objectForm, setObjectForm] = useState({ nome: '', formulario: '' });


    const loadForm = async () => {
        if (secao?.id != null) {
            const data = await SecaoService.getSecaoById(secao.id);
            if (data != null) {
                setFormValues(data);
            }

        }
    };
    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        values.formulario = objectForm;
        delete values.profissional_id;
        delete values.empresa_id;
        if(typeof values.formulario !== "string") values.formulario = JSON.stringify( values.formulario)
        SecaoService.createSecao(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            onRetorno(true);
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
    };
    const handleObjectChange = (objeto) => {
        setObjectForm(JSON.stringify(objeto));
    };
    useEffect(() => {
        setFormValues(initialValues);
        loadForm();
    }, []);


    return (
        <Fragment>
            <div className="row">
                <div className='col-sm-9'>
                    <MontarJsonInput onObjectChange={handleObjectChange} secaoId={secao?.id} />
                </div>
                <div className='col-sm-3'>
                    <Card>
                        <Card.Header className=' border-0 pb-0'>
                            <Card.Title>Título da Seção</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={formValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        setTimeout(() => {
                                            handleSubmit(values, { setSubmitting, setErrors });
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({ values, handleChange, handleBlur, touched, errors }) => (
                                        <Form>
                                            <div className='row'>


                                                <div className="form-group mb-1 col-lg-12">
                                                    <label className="text-label">Nome</label>
                                                    <Field
                                                        type="text"
                                                        name="nome"
                                                        className={`form-control form-control-sm ${touched.nome && errors.nome ? 'is-invalid' : 'is-valid'}`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.nome}
                                                    />
                                                    <ErrorMessage name="nome" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>
                                            <div className="form-group mt-2 pt-2">
                                                <button type="submit" className="btn btn-primary btn-sm">Salvar configuração</button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        </Fragment>
    )
}
export default SecaoForm;