import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class ProfissionalAgendaService {

  async getProfissionalAgendas(params) {

    const cacheKey = `profissional-agendas`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/profissional-agendas`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getProfissionalAgendaById(id) {
    const cacheKey = `profissional-agendas-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/profissional-agendas/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createProfissionalAgenda(data) {
    SessionService.removeAll(`profissional-agendas`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/profissional-agendas', data);
      return response.data;
    } else {
      return await this.updateProfissionalAgenda(data.id, data);
    }

  }

  async updateProfissionalAgenda(id, data) {
    SessionService.removeAll(`profissional-agendas`);
    const response = await axiosInstance2.put(`/profissional-agendas/${id}`, data);
    return response.data;

  }


  async deleteProfissionalAgenda(id) {
    SessionService.removeAll(`profissional-agendas`);
    const response = await axiosInstance2.delete(`/profissional-agendas/${id}`);
    return response.data;

  }
}

export default new ProfissionalAgendaService();
