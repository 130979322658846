import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class ProcedimentoService {

  async getProcedimentos(params) {

    const cacheKey = `procedimentos`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/procedimentos`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getProcedimentoById(id) {
    const cacheKey = `procedimentos-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/procedimentos/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createProcedimento(profissionalData) {
    SessionService.removeAll(`procedimentos`);
    if (profissionalData.id == null) {
      const response = await axiosInstance2.post('/procedimentos', profissionalData);
      return response.data;
    } else {
      return await this.updateProcedimento(profissionalData.id, profissionalData);
    }

  }

  async updateProcedimento(id, profissionalData) {
    SessionService.removeAll(`procedimentos`);
    const response = await axiosInstance2.put(`/procedimentos/${id}`, profissionalData);
    return response.data;

  }


  async deleteProcedimento(id) {
    SessionService.removeAll(`procedimentos`);
    const response = await axiosInstance2.delete(`/procedimentos/${id}`);
    return response.data;

  }
}

export default new ProcedimentoService();
