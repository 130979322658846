import  { axiosInstance2 } from '../services/AxiosInstance2';

class EmpresaProfissionalService {


  async getEmpresaProfissionais(params) {
      const response = await axiosInstance2.get('/empresa-profissionais',{ params });
      return response.data;

  }

  

  async getEmpresaProfissionalById(id) {
      const response = await axiosInstance2.get(`/empresa-profissionais/${id}`);
      return response.data;

  }


  async createEmpresaProfissional(profissionalData) {
    if(profissionalData.id == null){
      const response = await axiosInstance2.post('/empresa-profissionais', profissionalData);
      return response.data;
    }else{
        return  await this.updateEmpresaProfissional(profissionalData.id, profissionalData);
    }

  }

  async updateEmpresaProfissional(id, profissionalData) {
      const response = await axiosInstance2.put(`/empresa-profissionais/${id}`, profissionalData);
      return response.data;

  }

 
  async deleteEmpresaProfissional(id) {
      const response = await axiosInstance2.delete(`/empresa-profissionais/${id}`);
      return response.data;

  }

}

export default new EmpresaProfissionalService();
