import "react-toastify/dist/ReactToastify.css";

import { estadosService } from "../../services/EstadosService";

import React, { Fragment, useEffect, useState } from 'react';
import { Field, ErrorMessage } from 'formik';
//import Creatable from 'react-select/creatable';
import Select from "react-select";
import EspecidalidadeService from "../../services/EspecidalidadeService";
import ProfissaoService from "../../services/ProfissaoService";


const ProfissionalForm = ({ errors, touched, handleChange, handleBlur, setFieldValue, values }) => {
    const [profissoes, setProfissoes] = useState([])
    const [especialidadesOptions, setEspecialidadesOptions] = useState([]);

    const handleProfissaoChange = async (profissaoId, setFieldValue) => {
        const filteredEspecialidades = await EspecidalidadeService.getEspecidades({ "profissao_id": profissaoId });
        setFieldValue('especialidades', []); // Limpa as opções selecionadas ao mudar a profissao_id
        setEspecialidadesOptions(filteredEspecialidades);
    };

    const handleEspecialidadesChange = (selectedOptions, setFieldValue) => {
        //  const cleanedOptions = selectedOptions.filter((option) => option.id !== 'nova');
        setFieldValue('profissional.especialidades', selectedOptions);
    };
    useEffect(() => {
        // if (values?.especialidades.length > 0) {
        //     setFieldValue('profissional.especialidades', values?.especialidades.map(item => ({
        //         label: item.label,
        //         value: item.value
        //     })));
        // }

        const profissoesFilter = async () => {
            const data = await ProfissaoService.getProfissao();
            setProfissoes(data);
            await handleProfissaoChange(values.profissao_id, setFieldValue);

        };
        profissoesFilter();
    }, []);

    return (
        <Fragment>
            {/* {JSON.stringify(errors)}
            {JSON.stringify(touched)} */}
            <div className="row">
                <div className="form-group mb-3 col-lg-3">
                    <label className="text-label">Tratamento</label>
                    <Field
                        as="select"
                        name="profissional.tratamento"
                        className={`form-control ${touched?.tratamento ? (errors?.tratamento ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}

                    >
                        <option value="" >Selecione</option>
                        <option value="Dra.">Dra.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Sra.">Sra.</option>
                        <option value="Sr.">Sr.</option>
                    </Field>
                    <ErrorMessage name="profissional.tratamento" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>
                <div className="form-group mb-3 col-lg-9">
                    <label className="text-label">Profissão</label>
                    <Field
                        as="select"
                        name="profissional.profissao_id"
                        className={`form-control ${touched?.profissao_id ? (errors?.profissao_id ? 'is-invalid' : 'is-valid') : ''}`}
                        // values={values.profissao_id}
                        onChange={(e) => {
                            handleChange(e);
                            handleProfissaoChange(parseInt(e.target.value), setFieldValue);
                        }}

                    >

                        <option value="" >Selecione uma Profissão</option>
                        {profissoes.map((profissao) => (
                            <option key={profissao.id} value={profissao.id}>
                                {profissao.nome}
                            </option>
                        ))}
                    </Field>

                    <ErrorMessage name="profissional.profissao_id" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>
                <div className="form-group mb-12 col-lg-12">
                    <label className="text-label">Especialidades</label>
                    <Select

                        name="profissional.especialidades"
                        options={especialidadesOptions.map((especialidade) => ({
                            value: especialidade.id,
                            label: especialidade.nome,
                        }))}
                        className={`form-control  ${touched?.especialidades ? (errors?.especialidades ? 'is-invalid' : 'is-valid') : ''}`}
                        isMulti
                      //  formatCreateLabel={(inputValue) => `Criar nova especialidade: ${inputValue}`}
                        onChange={(selectedOptions) => handleEspecialidadesChange(selectedOptions, setFieldValue)}
                        value ={values?.especialidades && values?.especialidades.map(item => ({
                            value: item.value,
                            label: item.label
                           
                        }))}

                    />
                    <ErrorMessage name="profissional.especialidades" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>
                <div className="form-group mb-3 col-lg-8">
                    <label className="text-label">Nome do Conselho</label>
                    <Field
                        type="text"
                        name="profissional.nome_conselho"
                        className={`form-control  ${touched?.nome_conselho ? (errors?.nome_conselho ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    ////values={values.nome_conselho}
                    />
                    <ErrorMessage name="profissional.nome_conselho" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-4">
                    <label className="text-label">Número do Conselho</label>
                    <Field
                        type="text"
                        name="profissional.numero_conselho"
                        className={`form-control  ${touched?.numero_conselho ? (errors?.numero_conselho ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    // //values={values.numero_conselho}
                    />
                    <ErrorMessage name="profissional.numero_conselho" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-4">
                    <label className="text-label">Registro RQE</label>
                    <Field
                        type="text"
                        name="profissional.rqe"
                        className={`form-control  ${touched?.rqe ? (errors?.rqe ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.rqe}
                    />
                    <ErrorMessage name="profissional.rqe" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-4">
                    <label className="text-label">CNES</label>
                    <Field
                        type="text"
                        name="profissional.cnes"
                        className={`form-control  ${touched?.cnes ? (errors?.cnes ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.cnes}
                    />
                    <ErrorMessage name="profissional.cnes" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-4">
                    <label className="text-label">UF do Conselho</label>
                    <Field
                        as="select"
                        name="profissional.uf_conselho"
                        className={`form-control  ${touched?.uf_conselho ? (errors?.uf_conselho ? 'is-invalid' : 'is-valid') : ''}`}
                    >
                        <option value="" disabled>Selecione um estado</option>
                        {estadosService.map((estado) => (
                            <option key={estado.sigla} value={estado.sigla}>
                                {estado.descricao}
                            </option>
                        ))}
                    </Field>

                    <ErrorMessage name="profissional.uf_conselho" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Link do Site</label>
                    <Field
                        type="text"
                        name="profissional.link_site"
                        className={`form-control  ${touched?.link_site ? (errors?.link_site ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.link_site}
                    />
                    <ErrorMessage name="profissional.link_site" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Link do Facebook</label>
                    <Field
                        type="text"
                        name="profissional.link_facebook"
                        className={`form-control  ${touched?.link_facebook ? (errors?.link_facebook ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.link_facebook}
                    />
                    <ErrorMessage name="profissional.link_facebook" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Link do YouTube</label>
                    <Field
                        type="text"
                        name="profissional.link_youtube"
                        className={`form-control  ${touched?.link_youtube ? (errors?.link_youtube ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.link_youtube}
                    />
                    <ErrorMessage name="profissional.link_youtube" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Link do Instagram</label>
                    <Field
                        type="text"
                        name="profissional.link_instagram"
                        className={`form-control  ${touched?.link_instagram ? (errors?.link_instagram ? 'is-invalid' : 'is-valid') : ''}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    //values={values.link_instagram}
                    />
                    <ErrorMessage name="profissional.link_instagram" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>
            </div>

        </Fragment>
    );
};

export default ProfissionalForm;
