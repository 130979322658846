import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

/// Images
import avatar1 from '../../../images/avatar/1.jpg'
import avatar2 from '../../../images/avatar/2.jpg'
import avatar3 from '../../../images/avatar/3.jpg'
import avatar4 from '../../../images/avatar/4.jpg'
import avatar5 from '../../../images/avatar/5.jpg'
import MsgBox from './MsgBox'
import ChatService from '../../../services/ChatService'

const Chat = ({  toggleChatBox, toggleTab }) => {
  const [openMsg, setOpenMsg] = useState(false)
  const [chats, setChats] = useState([]);
  const [chat, setChat] = useState({});
  const loadData = async () => {
    const setChatsRetorno = await ChatService.getChats(1);
    setChats(setChatsRetorno.chats);
 };
 const getChatById = async (data) => {
    setChat(data);
    setOpenMsg(true)
 }
 useEffect(() => {
  loadData();
}, []);
  return (
    <div
      className={`tab-pane fade  ${toggleTab === 'chat' ? 'active show' : ''}`}
      id='chat'
      role='tabpanel'
    >
      <div
        className={`card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box ${
          openMsg ? 'd-none' : ''
        }`}
      >
        <div className='card-header chat-list-header text-center'>
          {/* <Link to='#'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width='18px'
              height='18px'
              viewBox='0 0 24 24'
              version='1.1'
            >
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <rect
                  fill='#000000'
                  x='4'
                  y='11'
                  width='16'
                  height='2'
                  rx='1'
                />
                <rect
                  fill='#000000'
                  opacity='0.3'
                  transform='translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) '
                  x='4'
                  y='11'
                  width='16'
                  height='2'
                  rx='1'
                />
              </g>
            </svg>
          </Link> */}
          <div>
            <h6 className='mb-1'>Chat List</h6>
            {/* <p className='mb-0'>Show All</p> */}
          </div>
          {/* <Link to='#'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              width='18px'
              height='18px'
              viewBox='0 0 24 24'
              version='1.1'
            >
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <rect x='0' y='0' width='24' height='24' />
                <circle fill='#000000' cx='5' cy='12' r='2' />
                <circle fill='#000000' cx='12' cy='12' r='2' />
                <circle fill='#000000' cx='19' cy='12' r='2' />
              </g>
            </svg>
          </Link> */}
        </div>
        <div
          className={`card-body contacts_body p-0 dz-scroll  ${
            toggleChatBox ? '' : ''
          }`}
          id='DZ_W_Contacts_Body'
        >
          <ul className='contacts'>
          {chats && (chats.map(chat => (
          <li
              className='active dz-chat-user'
              onClick={() => getChatById(chat)}
            >
              <div className='d-flex bd-highlight'>
                <div className='img_cont'>
                  <img
                    src={avatar1}
                    className='rounded-circle user_img'
                    alt=''
                  />
                  <span className='online_icon'></span>
                </div>
                <div className='user_info'>
                  <span>{chat.nome}</span>
                  <p>{chat.celular}</p>
                </div>
              </div>
            </li>
            )))}
          </ul>

        </div>
      </div>
      <MsgBox
        avatar1={avatar1}
        avatar2={avatar2}
        openMsg={openMsg}
        chat={chat}
        //PerfectScrollbar={PerfectScrollbar}
        offMsg={() => setOpenMsg(false)}
      />
    </div>
  )
}

export default Chat
