import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class AgendaTipoService {

  async getAgendaTipos(params) {

    const cacheKey = `agenda-tipos`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/agenda-tipos`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getAgendaTipoById(id) {
    const cacheKey = `agenda-tipos-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/agenda-tipos/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createAgendaTipo(data) {
    SessionService.removeAll(`agenda-tipos`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/agenda-tipos', data);
      return response.data;
    } else {
      return await this.updateAgendaTipo(data.id, data);
    }

  }

  async updateAgendaTipo(id, data) {
    SessionService.removeAll(`agenda-tipos`);
    const response = await axiosInstance2.put(`/agenda-tipos/${id}`, data);
    return response.data;

  }


  async deleteAgendaTipo(id) {
    SessionService.removeAll(`agenda-tipos`);
    const response = await axiosInstance2.delete(`/agenda-tipos/${id}`);
    return response.data;

  }
}

export default new AgendaTipoService();
