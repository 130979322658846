import { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class SecretariaService {


  async getSecretarias(params) {
    // const cacheKey = `secretarias`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get('/secretarias', { params });
   // SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }



  async getSecretariaById(id) {
    // const cacheKey = `secretarias-${id}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }

    const response = await axiosInstance2.get(`/secretarias/${id}`);
  //  SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createSecretaria(secretariaData) {
    SessionService.removeAll(`secretarias`);
    if (secretariaData.id == null) {
      const response = await axiosInstance2.post('/secretarias', secretariaData);
      return response.data;
    } else {
      return await this.updateSecretaria(secretariaData.id, secretariaData);
    }

  }

  async updateSecretaria(id, secretariaData) {
    const response = await axiosInstance2.put(`/secretarias/${id}`, secretariaData);
    return response.data;

  }


  async deleteSecretaria(id) {
    const response = await axiosInstance2.delete(`/secretarias/${id}`);
    return response.data;

  }

}

export default new SecretariaService();
