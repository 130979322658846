import React, { Fragment, useEffect, useRef, useState } from "react";


import { Button, ListGroup, Tab } from "react-bootstrap";
import SecaoService from "../../services/SecaoService";
import { Form, Formik } from "formik";
import DynamicInput from "../Empresa/Secao/DynamicInput";
import InteracaoAtendimentoService from "../../services/InteracaoAtendimentoService";
import { toast } from "react-toastify";
import InteracaoService from "../../services/InteracaoService";
import ProntuarioService from "../../services/ProntuarioService";
import PessoaForm from "../Pessoa/PessoaForm";
import Historico from "./Historico";
import { createSlug } from "../../helper/Helpers";

const ProntuarioForm = ({id, agenda_id}) => {
    const [secoes, setSecoes] = useState([]);
    const [secao, setSecao] = useState(null);
    const [initialValues, setInitialValues] = useState(null);
    const [compontesForm, setCompontesForm] = useState([]);
    const [consulta, setConsulta] = useState(null);
    const [prontuario, setProntuario] = useState(null);
 
    const loadSecoes = async () => {
        const data = await SecaoService.getSecoes({});
        setSecoes(data);
    }
    const loadProntuario = async () => {
        const data = await ProntuarioService.getProntuarioById(id);
        setProntuario(data);
    } 
    const localInteracao = async () => {
        const data = await InteracaoService.getInteracoes({prontuario_id:id});
        console.log(data);

        
        if(data && data.some(i => i.agenda_id == agenda_id)){
            const consultaSelectedIndex= data.findIndex(i => i.agenda_id == agenda_id);
            const consultaSelected = data[consultaSelectedIndex];

            console.log(consultaSelected);
            setConsulta(consultaSelected);
            if(consultaSelected.finalizado == 0){
                setTimeout(() => {
                   // toggleTimer(parseDateTime(consultaSelected.data,consultaSelected.hora_ini));
                }, 1000);
            }
        }
       
    }
    const parseDateTime = (dateStr, timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const date = new Date(dateStr);
       
        date.setDate(date.getDate() + 1)
        date.setHours(hours);
        date.setMinutes(minutes);
        console.log(date);
        return date;
    };
 

  
    const selecionarSecao = (s) => {
        setSecao(s);
        let formulario = JSON.parse(s.formulario);
        setCompontesForm(formulario);
        const tratarInitialValues = formulario.reduce((acc, curr) => {
            acc[createSlug(curr.titulo)] = "";
            return acc;
        }, {});

        if (consulta) {
            InteracaoAtendimentoService.getInteracaoAtendimentos({ interacao_id: consulta.id }).then(data => {
                //se ja existir eu pego os valores e adiciono no objeto
                if (data.length > 0) {
                    const formularioSecao = data.find(f => f.secao_id === s.id);
                    if (formularioSecao?.descricao) {
                        const formularioValor = JSON.parse(formularioSecao?.descricao);
                        for (const key in tratarInitialValues) {
                            tratarInitialValues[key] = formularioValor[key];
                        }
                    }
                }
            });

        }
        setInitialValues(tratarInitialValues);
    }
    const iniciarFecharConsulta = () => {
        let atendimento = { prontuario_id: id, agenda_id: agenda_id, teleatendimento: 0 };
        if (consulta) {
            atendimento = { id: consulta.id };
        }
        InteracaoService.createInteracao(atendimento).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            setConsulta(data);
           // toggleTimer();

        }).catch(error => {
            toast.warning(error.message, {
                position: "top-right",
                autoClose: 5000
            });
        });
    };
    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        const integracaoData = {
            interacao_id: consulta.id,
            secao_id: secao.id,
            descricao: JSON.stringify(values)|| ""
        }
        InteracaoAtendimentoService.createInteracaoAtendimento(integracaoData).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });

        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
    };
    useEffect(() => {
        loadProntuario();
        loadSecoes();
        localInteracao();

        
    }, []);
    return (
        <Fragment>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-3 col-xxl-4">
                                        <div className="">
                                            <div className="p-0">
                                                
                                              
                                       
                                                {
                                                
                                                   consulta == null ? (
                                                            <Button className="btn btn-primary btn-block" onClick={() => { iniciarFecharConsulta() }}> Iniciar Consulta  </Button>
                                                        ) :  consulta?.finalizado === 0 ? (
                                                            <Button className="btn btn-danger mt-2 btn-block" onClick={() => { iniciarFecharConsulta() }}>Encerrar Consulta</Button>
                                                        ) : ""
                                                    }
                                            </div>
                                            <ListGroup className="rounded  mt-4 " >
                                                <ListGroup.Item action variant="info" eventKey="#link1">
                                                    <i className="fa fa-inbox font-18 align-middle me-2"></i> Histórico Clínico
                                                </ListGroup.Item>
                                                <ListGroup.Item action variant="info" eventKey="#link2">
                                                    <i className="fas fa-star font-18 align-middle me-2"></i> Dados do Paciente
                                                </ListGroup.Item>

                                            </ListGroup>
                                            {consulta != null && (
                                                <ListGroup className="rounded overflow-hidden mt-4" onSelect={(k) => console.log(k)}>
                                                    <div className="intro-title d-flex justify-content-between my-0">
                                                        <h5>Seções</h5>
                                                    </div>
                                                    {secoes.map((secao, i) => (
                                                        <ListGroup.Item className="list-group-item" variant="info" action eventKey={`#sessao-${secao.id}`} key={i} onClick={() => selecionarSecao(secao)} >
                                                            <span className="icon-warning">
                                                                <i
                                                                    className="fa fa-circle me-2"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                            {secao?.nome}
                                                        </ListGroup.Item >
                                                    ))}
                                                </ListGroup>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-9 col-xxl-8">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="#link1"><Historico prontuario_id={id} /></Tab.Pane>
                                            <Tab.Pane eventKey="#link2">
                                                {prontuario?.paciente_id && (
                                                    <div className='card'>
                                                        <div className='card-header border-0 pb-0'>
                                                            <h4 className='card-title'>Dados do Paciênte</h4>
                                                        </div>
                                                        <div className='card-body'>
                                                            <PessoaForm pessoa={{ tipo: 'Paciente', id: prontuario?.paciente_id }} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Tab.Pane>
                                            {secoes.map((secao, i) => (
                                                <Tab.Pane eventKey={`#sessao-${secao.id}`} key={i} >
                                                    <div className='card'>
                                                  
                                                        <div className='card-header border-1 pb-3'>
                                                            <h4 className='card-title' style={{ color: '#205e79' }}>{secao.nome}</h4>
                                                        </div>
                                                        <div className='card-body'>
                                                            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={(values, { setSubmitting, setErrors }) => {
                                                                setTimeout(() => {
                                                                    handleSubmit(values, { setSubmitting, setErrors });
                                                                    setSubmitting(false);
                                                                }, 400);
                                                            }}>
                                                                <Form>
                                                                    <div className='row p-3'  style={{border: '1px dashed #dee2e6', backgroundColor: '#f8f9fa'}}>
                                                                        {compontesForm.map((f, i) => (<DynamicInput key={i} type={f.tipo} titulo={f.titulo} name={createSlug(f.titulo)} order={f.order} opcoes={f.lista} />))}
                                                                    </div>
                                                                    <div className="form-head d-flex mb-1 md-1 align-items-start">
                                                                        <button type="submit" className="btn btn-info ms-auto  mt-3 btn-block  text-center">Salvar {secao.nome}</button>
                                                                    </div>
                                                                </Form>
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            ))}


                                        </Tab.Content>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </Fragment>
    );
};

export default ProntuarioForm;