import * as Yup from 'yup';


const pacienteInitialValues = {
    pessoa_id: 0,
    escolaridade_id: 0,
    naturalidade: '',
    estado_civil: '',
    profissao: '',
    nome_mae: '',
    nome_pai: '',
    nome_responsavel: '',
    contato_emergencia: '',
    telefone_emergencia: '',
    tipo_sangue: '',
    obito: false,
    causa_mortis: ''
  };
  

  const pacienteValidationSchema = {
    escolaridade_id: Yup.number().nullable(),
    naturalidade: Yup.string().max(50, 'O campo naturalidade deve ter no máximo 50 caracteres'),
    estado_civil: Yup.string().max(50, 'O campo estado_civil deve ter no máximo 50 caracteres'),
    profissao: Yup.string().max(100, 'O campo profissao deve ter no máximo 100 caracteres'),
    nome_mae: Yup.string().max(100, 'O campo nome_mae deve ter no máximo 100 caracteres'),
    nome_pai: Yup.string().max(100, 'O campo nome_pai deve ter no máximo 100 caracteres'),
    nome_responsavel: Yup.string().max(100, 'O campo nome_responsavel deve ter no máximo 100 caracteres'),
    contato_emergencia: Yup.string().max(100, 'O campo contato_emergencia deve ter no máximo 100 caracteres'),
    telefone_emergencia: Yup.string().max(20, 'O campo telefone_emergencia deve ter no máximo 20 caracteres'),
    tipo_sangue: Yup.string().max(5, 'O campo tipo_sangue deve ter no máximo 5 caracteres'),
    causa_mortis: Yup.string().max(100, 'O campo causa_mortis deve ter no máximo 100 caracteres')
  };
  export { pacienteInitialValues, pacienteValidationSchema };