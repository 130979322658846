import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useEffect, useState, } from 'react';
import CreatableSelect from 'react-select/creatable';
import * as Yup from 'yup';
import DynamicInput from './DynamicInput';

import { Button, Card } from 'react-bootstrap';
import SecaoService from '../../../services/SecaoService';

const novo = { tipo: '', titulo: '', lista: [], order: 0 };
const MontarJsonInput = ({ onObjectChange , secaoId}) => {

    const [mostrarLista, setMostrarLista] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [opcoesSelect, setOpcoesSelect] = useState([]);
    const [componentes, setComponentes] = useState([]);
    const [formAdd, setFormAdd] = useState(novo);

    const tipos = [
        { value: 'text', label: 'Texto curto' },
        { value: 'number', label: 'apenas número' },
        { value: 'date', label: 'Data' },
        { value: 'hora', label: 'Hora' },
        { value: 'textoRico', label: 'Texto Grande' },
        { value: 'select', label: 'Lista para selecionar uma opção ' },
        { value: 'checkbox', label: 'Lista para marcar várias opções' },
        { value: 'radio', label: 'Lista para selecionar uma opção' }
    ];

    const temLista = (valor) => {
        setMostrarLista(['select', 'multselect', 'checkbox', 'radio'].includes(valor.target.value));
    };

    const handleSubmit = (values, { resetForm }) => {
        componentes.push(values);
        setComponentes(componentes);
        setFormValues(initialValues);
        setOpcoesSelect([]);
        setFormAdd(novo);
        resetForm(novo);
        setMostrarLista(false); // Desabilita o mostrarLista
        onObjectChange(componentes);
    };

    const handleMultiSelectChange = (selectedOptions, { setFieldValue }) => {
        const newOptions = selectedOptions.filter((option) => !opcoesSelect.find((existingOption) => existingOption.value === option.value));
        if (newOptions.length > 0) {
            setOpcoesSelect((prevOptions) => [...prevOptions, ...newOptions]);
        }
        setFieldValue('lista', selectedOptions);
    };
    const createSlug = (text) => {
        text = text.toLowerCase();
        text = text.replace(/\s+/g, "-");
        text = text.replace(/[^\w-]+/g, "");
        text = text.replace(/^-+|-+$/g, "");
        return text;
    };
    const initialValues = componentes.reduce((acc, curr) => {
        acc[createSlug(curr.titulo)] = "";
        return acc;
    }, {});
    const editar = async (secaoId) => {
       const data =  await SecaoService.getSecaoById(secaoId);
       let formulario = JSON.parse(data.formulario);
       setComponentes(formulario);

    }
    const remover  = (index) => {
        const novosComponentes = [...componentes];
        novosComponentes.splice(index, 1);
        setComponentes(novosComponentes);
        onObjectChange(novosComponentes);
        
    }

    useEffect(() => {
         if(secaoId)
             editar(secaoId);
        setFormValues(initialValues);

    }, []);
    return (
        <Fragment>
            <div className='row'>
                <div className='col-lg-4'>
                <Card>
                <Card.Header className=' border-0 pb-0'>
                    <Card.Title>Montar formulários do prontuário</Card.Title>
                </Card.Header>
                <Card.Body>
    
                        <Formik  enableReinitialize={true} initialValues={formAdd} validationSchema={
                            Yup.object().shape({
                                titulo: Yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres'),
                                tipo: Yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres'),
                                // lista: Yup.array()
                                //     .min(0, "")
                                //     .when("tipo", {
                                //         is: (tipo) => ["select", "multselect", "checkbox", "radio"].includes(tipo),
                                //         then: Yup.array().min(2, "Lista deve ter pelo menos dois itens"),
                                //       }),
                            })
                        }
                        onSubmit={handleSubmit}>
                            {({ values, handleChange, handleBlur, touched, errors, setFieldValue, field,resetForm }) => (
                                <Form>
                                    <div className='row'>
                                        <div className="form-group mt-2 mb-2 col-lg-12">
                                            <label className="text-label">Titulo</label>
                                            <Field
                                                type="text"
                                                name="titulo"
                                                className={`form-control form-control-sm ${touched.titulo && errors.titulo ? 'is-invalid' : 'is-valid'}`}
                                            />
                                            <ErrorMessage name="titulo" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group mt-2 mb-2 col-lg-12">
                                            <label className="text-label">Tipo de Campo</label>
                                            <Field
                                                as="select"
                                                name="tipo"
                                                className={`form-control form-control-sm ${touched.tipo && errors.tipo ? 'is-invalid' : 'is-valid'}`}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    temLista(e)
                                                }}
                                                onBlur={handleBlur}
                                                value={values.tipo}
                                            >
                                                <option  >Selecione o Tipo de Campo</option>
                                                {tipos.map((tipo) => (
                                                    <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="tipo" component="div" className="invalid-feedback" />
                                        </div>
                     
                                        {mostrarLista && (
                                            <div className="form-group col-lg-12 mt-2 mb-2">
                                                <Field name="multiSelect">
                                                    {({ form }) => (
                                                        <div>
                                                            <label htmlFor="multiSelect">Seleção Múltipla</label>
                                                            <CreatableSelect
                                                                id="lista"
                                                                name="lista"
                                                                options={opcoesSelect}
                                                                className={`  ${touched?.lista ? (errors?.lista ? 'is-invalid' : 'is-valid') : ''}`}
                                                                isMulti

                                                                onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, form)}
                                                                formatOptionLabel={(inputValue) => `${inputValue.label}`}
                                                            />

                                                            <ErrorMessage name="lista" component="div" className="text-danger" />
                                                        </div>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="lista" component="div" className="invalid-feedback" />
                                            </div>
                                        )}
                                                           <div className="form-group mt-2 mb-2 col-lg-12">
                                            <button type="submit" className="btn btn-primary btn-sm " style={{float: 'right'}}>Adicionar &nbsp; {">>"}</button>
                                        </div>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                
                </Card.Body>
            </Card>
               </div>
               <div className='col-lg-8'>  
               <Card>
                <Card.Header className=' border-0 pb-0'>
                    <Card.Title>formulário</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text></Card.Text>
                    <Formik initialValues={formValues} enableReinitialize={true}>
                        <Form>
                            <div className=' p-3' style={{border: '1px dashed #dee2e6', backgroundColor: '#f8f9fa'}}>
                                {componentes.map((f, i) => (<div style={{borderBottom: '1px dashed #dee2e6'}} className='row'><div className='col-lg-10 mt-3 mb-3'><DynamicInput key={i} type={f.tipo} titulo={f.titulo} name={createSlug(f.titulo)} order={f.order} opcoes={f.lista} /></div><div className='col-lg-2 mt-4 pt-2'><Button className='btn btn-danger btn-sm ' onClick={() => remover(i)}>Remover</Button></div></div>))}
                            </div>
                        </Form>
                    </Formik>
                </Card.Body>
            </Card>
                </div>
            </div>


        </Fragment >
    )
}

export default MontarJsonInput;