import { axiosInstance2 } from '../services/AxiosInstance2';

class ProfissionalService {


  async getProfissionais(params) {
    const response = await axiosInstance2.get('/profissionais', { params });
    return response.data;

  }



  async getProfissionalById(id) {
    const response = await axiosInstance2.get(`/profissionais/${id}`);
    return response.data;

  }


  async createProfissional(profissionalData) {
    if (profissionalData.id == null) {
      const response = await axiosInstance2.post('/profissionais', profissionalData);
      return response.data;
    } else {
      return await this.updateProfissional(profissionalData.id, profissionalData);
    }

  }

  async updateProfissional(id, profissionalData) {
    const response = await axiosInstance2.put(`/profissionais/${id}`, profissionalData);
    return response.data;

  }


  async deleteProfissional(id) {
    const response = await axiosInstance2.delete(`/profissionais/${id}`);
    return response.data;

  }

}

export default new ProfissionalService();