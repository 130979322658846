import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initialValues, validationSchema } from "../../models/EmpresaConfiguracao"
import EmpresaConfiguracaoService from "../../services/EmpresaConfiguracaoService";


const EmpresaConfiguracaoForm = () => {
    const [formValues, setFormValues] = useState({})

    useEffect(() => {
        setFormValues(initialValues);
        const loadEmpresaConfiguracao = async () => {
            const data = await EmpresaConfiguracaoService.getEmpresaConfiguracaos();
            if(data != null & data.length > 0){
   
                    setFormValues(data[0]);
            }
        };

        loadEmpresaConfiguracao();
    }, []);


    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        EmpresaConfiguracaoService.createEmpresaConfiguracao(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
        setSubmitting(false);
    };
    const opcoesVisualizacaoAgenda = [
        { id: 'dayGridMonth', value: 'Mês' },
        { id: 'timeGridWeek', value: 'Semana' },
        { id: 'timeGridDay', value: 'Dia' },
    ];

    return (
        <Fragment>
            <div className="basic-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        setTimeout(() => {
                            handleSubmit(values, { setSubmitting, setErrors });
                        }, 400);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                        <Form>
                            <div className="row">
                                <div className="form-group mb-1 col-lg-6">
                                    <label className="text-label">Hora de Início da Agenda</label>
                                    <Field
                                        type="text"
                                        name="hora_ini_agenda"
                                        className={`form-control  form-control-sm ${touched.hora_ini_agenda && errors.hora_ini_agenda ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.hora_ini_agenda}
                                        as={InputMask}
                                        mask="99:99"
                                    />
                                    <ErrorMessage name="hora_ini_agenda" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-1 col-lg-6">
                                    <label className="text-label">Hora de Fim da Agenda</label>
                                    <Field
                                        type="text"
                                        name="hora_fim_agenda"
                                        className={`form-control  form-control-sm ${touched.hora_fim_agenda && errors.hora_fim_agenda ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.hora_fim_agenda}
                                        as={InputMask}
                                        mask="99:99"
                                    />
                                    <ErrorMessage name="hora_fim_agenda" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-1 col-lg-6">
                                    <label className="text-label">Duração do Atendimento</label>
                                    <Field
                                        type="text"
                                        name="duracao_atendimento"
                                        min="5"
                                        className={`form-control  form-control-sm ${touched.duracao_atendimento && errors.duracao_atendimento ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.duracao_atendimento}
                                        as={InputMask}
                                        mask="99:99"
                                    />
                                    <ErrorMessage name="duracao_atendimento" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-1 col-lg-6">
                                    <label className="text-label">Visualização da Agenda</label>
                                    <Field
                                        as="select"
                                        name="visualizacao_agenda"
                                        className={`form-control  form-control-sm ${touched.visualizacao_agenda && errors.visualizacao_agenda ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.visualizacao_agenda}
                                    >
                                        <option value="" label="Selecione uma opção" />
                                        {opcoesVisualizacaoAgenda.map(opcao => (
                                            <option key={opcao.id} value={opcao.id} label={opcao.value} />
                                        ))}
                                    </Field>
                                    <ErrorMessage name="visualizacao_agenda" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>


                            </div>
                            <button type="submit" className="btn btn-primary btn-sm" >
                                Enviar
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export default EmpresaConfiguracaoForm;
