import React, { useState, useEffect, Fragment } from 'react';
import { Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import EscolaridadeService from '../../services/EscolaridadeService';


const PacienteForm = ({errors, touched}) => {
    const [escolaridades, setEscolaridades] = useState([])

    const estadosCivis = [
        'Solteiro(a)',
        'Casado(a)',
        'Divorciado(a)',
        'Viúvo(a)',
        'Separado(a)',
        'União Estável',
    ];
    useEffect(() => {
        const loadForm = async () => {
            const escolaridadesRetorno = await EscolaridadeService.getEscolaridades();
            setEscolaridades(escolaridadesRetorno);
        };

        loadForm();
    }, []);


    return (
        <Fragment>
            <div className='row'>
                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Escolaridade</label>
                    <Field
                        name={`paciente.escolaridade_id`}
                        as="select"
                         className={`form-control  ${touched?.escolaridade_id? (errors?.escolaridade_id ? 'is-invalid' : 'is-valid'):''}`} 
                    >
                        <option value={0} >Selecione a Escolaridade</option>
                        {escolaridades.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.nome}
                            </option>
                        ))}
                    </Field>
                    <ErrorMessage name={`paciente.escolaridade_id`} component="div" className="invalid-feedback" /> 
                </div>
                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Naturalidade</label>
                    <Field
                        type="text"
                        name={`paciente.naturalidade`}
                        className={`form-control  ${touched?.naturalidade? (errors?.naturalidade ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.naturalidade`} component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Estado Civil</label>
                    <Field
                        name={`paciente.estado_civil`}
                        as="select"
                        className={`form-control  ${touched?.estado_civil? (errors?.estado_civil ? 'is-invalid' : 'is-valid'):''}`}
                    >
                        <option value="" disabled>Selecione o Estado Civil</option>
                        {estadosCivis.map((estadoCivil, index) => (
                            <option key={index} value={estadoCivil}>{estadoCivil}</option>
                        ))}
                    </Field>
                    <ErrorMessage name={`paciente.estado_civil`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Telefone de Emergência</label>
                    <Field
                        name={`paciente.telefone_emergencia`}
                        render={({ field }) => (
                            <InputMask
                                {...field}
                                mask="(99) 99999-9999"
                                maskChar=""
                                className={`form-control  ${touched?.telefone_emergencia? (errors?.telefone_emergencia ? 'is-invalid' : 'is-valid'):''}`}
                            />
                        )}
                    />
                    <ErrorMessage name={`paciente.telefone_emergencia`} component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Profissão</label>
                    <Field
                        type="text"
                        name={`paciente.profissao`}
                        className={`form-control  ${touched?.telefone_emergencia? (errors?.telefone_emergencia ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.profissao`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Nome da Mãe</label>
                    <Field
                        type="text"
                        name={`paciente.nome_mae`}
                        className={`form-control ${touched?.nome_mae? (errors?.nome_mae ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.nome_mae`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Nome do Pai</label>
                    <Field
                        type="text"
                        name={`paciente.nome_pai`}
                        className={`form-control ${touched?.nome_pai? (errors?.nome_pai ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.nome_pai`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Nome do Responsável</label>
                    <Field
                        type="text"
                        name={`paciente.nome_responsavel`}
                        className={`form-control ${touched?.nome_responsavel? (errors?.nome_responsavel ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.nome_responsavel`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Contato de Emergência</label>
                    <Field
                        type="text"
                        name={`paciente.contato_emergencia`}
                        className={`form-control ${touched?.contato_emergencia? (errors?.contato_emergencia ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.contato_emergencia`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-3">
                    <label className="text-label">Tipo Sanguíneo</label>
                    <Field
                        type="text"
                        name={`paciente.tipo_sangue`}
                        className={`form-control ${touched?.tipo_sangue? (errors?.tipo_sangue ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.tipo_sangue`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-3">
                    <label className="text-label">Óbito</label>
                    <Field
                        type="checkbox"
                        name={`paciente.obito`}
                        className={`form-check-input ${touched?.obito? (errors?.obito ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.obito`} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3 col-lg-6">
                    <label className="text-label">Causa Morte</label>
                    <Field
                        type="text"
                        name={`paciente.causa_mortis`}
                        className={`form-control ${touched?.causa_mortis? (errors?.causa_mortis ? 'is-invalid' : 'is-valid'):''}`}
                    />
                    <ErrorMessage name={`paciente.causa_mortis`} component="div" className="invalid-feedback" />
                </div>
            </div>   


        </Fragment>
    );
};

export default PacienteForm;