import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProcedimentoTipoService from '../../services/ProcedimentoTipoService';
import ProcedimentoService from '../../services/ProcedimentoService';
import { validationSchema, initialValues } from '../../models/Procedimento';

const ProcedimentosForm = ({ procedimento, onRetorno  }) => {
    const [formValues, setFormValues] = useState({})
    const [procedimentoTipo, setProcedimentoTipo] = useState([])
    useEffect(() => {
        setFormValues(initialValues);
        const loadForm = async () => {
            if (procedimento?.id != null) {
                const data = await ProcedimentoService.getProcedimentoById(procedimento.id);
                if (data != null) {
                    setFormValues(data);
                }

            }
            const tipos = await ProcedimentoTipoService.getProcedimentoTipos();
            setProcedimentoTipo(tipos);
        };

        loadForm();
    }, []);

    const handleSubmit = (values, { setSubmitting, setErrors }) => {
        ProcedimentoService.createProcedimento(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            onRetorno(true);
        }).catch(error => {
            if (error?.errors) {
              setErrors(error.errors);
              toast.warning(error.message, {
                position: "top-right",
                autoClose: 5000
              });
            }
          })
            .finally(() => {
              setSubmitting(false);
            });
    };
    return (
        <Fragment>
            <div className="basic-form">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        setTimeout(() => {
                          handleSubmit(values, { setSubmitting, setErrors });
                          setSubmitting(false);
                        }, 400);
                      }}
                >
                    {({ values, handleChange, handleBlur, touched, errors }) => (
                        <Form>
                            <div className='row'>
                                <div className="form-group mb-1 col-lg-4">
                                    <label className="text-label">Tipo de Procedimento</label>
                                    <Field
                                        as="select"
                                        name="procedimento_tipo_id"
                                        className={`form-control  form-control-sm ${touched.procedimento_tipo_id && errors.procedimento_tipo_id ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.procedimento_tipo_id}
                                    >
                                        <option value={0} disabled>Selecione o Tipo de Procedimento</option>
                                        {procedimentoTipo.map((tipo) => (
                                            <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="procedimento_tipo_id" component="div" className="invalid-feedback" />
                                </div>


                                <div className="form-group mb-1 col-lg-8">
                                    <label className="text-label">Nome</label>
                                    <Field
                                        type="text"
                                        name="nome"
                                        className={`form-control  form-control-sm ${touched.nome && errors.nome ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nome}
                                    />
                                    <ErrorMessage name="nome" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group mb-1 col-lg-4">
                                    <label className="text-label">Duração Minuto</label>
                                    <Field
                                        type="number"
                                        name="duracao_min"
                                        className={`form-control  form-control-sm ${touched.duracao_min && errors.duracao_min ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.duracao_min}
                                    />
                                    <ErrorMessage name="duracao_min" component="div" className="invalid-feedback" />
                                </div>
                                <div className="form-group mb-1 col-lg-4">
                                    <label className="text-label">Valor</label>
                                    <Field
                                        type="number"
                                        name="valor"
                                        className={`form-control  form-control-sm ${touched.valor && errors.valor ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.valor}
                                    />
                                    <ErrorMessage name="valor" component="div" className="invalid-feedback" />
                                </div>
                                {/* <div className="form-group mb-1 col-lg-4">
                                    <label className="text-label">Cor</label>
                                    <Field
                                        type="color"
                                        name="cor"
                                        className={`form-control  form-control-sm ${touched.cor && errors.cor ? 'is-invalid' : 'is-valid'}`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.cor}
                                    />
                                    <ErrorMessage name="cor" component="div" className="invalid-feedback" />
                                </div> */}

                            </div>
                            {/* Continue com os campos restantes do formulário, seguindo o mesmo padrão */}

                            <div className="form-group mb-1">
                                <button type="submit" className="btn btn-primary btn-sm">Enviar</button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export default ProcedimentosForm;
