import React from 'react';
import {  Spinner } from 'react-bootstrap';
import './CustomProgressBar.css';
const CustomProgressBar = () => {
  return (
    <div className="loading-indicator">
      <Spinner animation="border" role="status" animated >
        <span className="sr-only">Carregando...</span>
      </Spinner>
    </div>
  );
};

export default CustomProgressBar;
