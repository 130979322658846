import React, { useState, useEffect } from "react";

import { Button, Table } from "react-bootstrap";
import InputMask from 'react-input-mask';
import { toast } from "react-toastify";
import ProfissionalAgendaService from "../../../services/ProfissionalAgendaService";
// import moment from "moment";
// import 'moment/locale/pt-br'
const DoctorAgenda = () => {
    const [profissionalAgenda, setProfissionalAgenda] = useState([]);
    const [weeks, setWeeks] = useState({
        1: 'Domingo',
        2: 'Segunda',
        3: 'Terça',
        4: 'Quarta',
        5: 'Quinta',
        6: 'Sexta',
        7: 'Sabado'
    });
    const loadData = () => {

        ProfissionalAgendaService.getProfissionalAgendas({}).then(data => {
            const agendas = monstarHorarios();
            const atualizarHorarios = [];
            agendas.forEach((row, i) => {
                const findData = data.filter(f => f.dia == row.dia);
                if (findData != null && findData.length == 1) {
                    const horarios = {
                        id: findData[0].id, dia: findData[0].dia,
                        manha: { hora_ini: findData[0].hora_ini, hora_fim: findData[0].hora_fim }
                    };
                    atualizarHorarios.push(horarios);
                } else if (findData != null && findData.length == 2) {
                    const horarios = {
                        id: findData[0].id, dia: findData[0].dia,
                        manha: { hora_ini: findData[0].hora_ini, hora_fim: findData[0].hora_fim },
                        tarde: { hora_ini: findData[1].hora_ini, hora_fim: findData[1].hora_fim}
                    };
                    atualizarHorarios.push(horarios);

                }else{
                    atualizarHorarios.push(row);
                }
            });
            setProfissionalAgenda(atualizarHorarios);
        });

    }
    const handleUpdate = (dia, turno, field, value) => {
        setProfissionalAgenda(prevState => {
            const newState = [...prevState];
            const index = newState.findIndex(item => item.dia === dia);
            newState[index][turno][field] = value;
            return newState;
        });
    };

    const monstarHorarios = () => {
        const agendas = [];
        Array.from({ length: 7 }).map((_, index) => {
            agendas.push({ id: null, dia: index + 1, manha: { hora_ini: '', hora_fim: '' }, tarde: { hora_ini: '', hora_fim: '' } });
        })
        // setProfissionalAgenda(agendas);
        return agendas;
    }

    const handleSubmit = ({ id, dia, manha, tarde }) => {
        const horario = {
            id: id,
            dia: dia,
            hora_ini: manha ? manha.hora_ini : tarde.hora_ini,
            hora_fim: manha ? manha.hora_fim : tarde.hora_fim,
        }
        ProfissionalAgendaService.createProfissionalAgenda(horario).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
        }).catch(error => {
            if (error?.errors) {
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        });
    };

    useEffect(() => {

        loadData();
    }, []);
    return (
        <>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Dia</th>
                        <th>Início </th>
                        <th>Fim</th>
                        <th>  </th>
                        <th>Início</th>
                        <th>Fim</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {profissionalAgenda.map(({ id, dia, manha, tarde }) => (

                        <tr key={dia}>
                            <td>{weeks[dia]}</td>
                            <td>
                                <InputMask
                                    mask="99:99"
                                    className="form-control"
                                    value={manha?.hora_ini}
                                    onChange={(e) => handleUpdate(dia, "manha", "hora_ini", e.target.value)}
                                />
                            </td>
                            <td>
                                <InputMask
                                    mask="99:99"
                                    className="form-control"
                                    value={manha?.hora_fim}
                                    onChange={(e) => handleUpdate(dia, "manha", "hora_fim", e.target.value)}
                                />
                            </td>
                            <td>
                                <Button onClick={() => { handleSubmit({ id, dia, manha }) }} variant="primary">Atualizar</Button>
                            </td>
                            <td>
                                <InputMask
                                    mask="99:99"
                                    className="form-control"
                                    value={tarde?.hora_ini}
                                    onChange={(e) => handleUpdate(dia, "tarde", "hora_ini", e.target.value)}
                                />
                            </td>
                            <td>
                                <InputMask
                                    mask="99:99"
                                    className="form-control"
                                    value={tarde?.hora_fim}
                                    onChange={(e) => handleUpdate(dia, "tarde", "hora_fim", e.target.value)}
                                />
                            </td>
                            <td>
                                <Button onClick={() => { handleSubmit({ id, dia, tarde }) }} variant="primary">Atualizar</Button>
                            </td>
                        </tr>

                    ))}
                </tbody>
            </Table>

        </>
    );
};

export default DoctorAgenda;
