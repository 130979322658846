import axios from 'axios';
import { useState} from 'react';
//import { toast } from "react-toastify";
import {API_URL} from '../config';

const axiosInstance2 = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
});


const useProgressBar = () => {
  const [loading, setLoading] = useState(false);
 // const [toastMessages, setToastMessages] = useState([]);

  axiosInstance2.interceptors.request.use((config) => {
    setLoading(true);
    return config;
  });

  axiosInstance2.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      setLoading(false);
      // if (!toastMessages.includes(error.message)) {
      //   toast.warning(error.message, {
      //     position: "top-right",
      //     autoClose: 5000
      // });
      //   setToastMessages([...toastMessages, error.message]);
      // }    

      return Promise.reject(error);
    }
  );

  return loading;
};

axiosInstance2.interceptors.request.use(function (config) {
  const tokenDetailsString = JSON.parse(localStorage.getItem('userDetails'));
   config.headers.Authorization =  `Bearer ${tokenDetailsString?.token}`;   
   return config;
 });
 axiosInstance2.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // O servidor respondeu com um status de erro (por exemplo, 4xx ou 5xx)
      console.error('Erro de resposta da API:', error.response.data);
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // A requisição foi feita, mas não houve resposta (por exemplo, problemas de rede)
      console.error('Erro na requisição para a API:', error.request);
      return Promise.reject(error.request);
    } else {
      // Algo aconteceu durante a configuração da requisição que causou o erro
      console.error('Erro durante a configuração da requisição para a API:', error.message);
      return Promise.reject(error.message);
    }
  }
);
export  { axiosInstance2, useProgressBar };
