import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class ProntuarioService {

  async getProntuarios(params) {

    // const cacheKey = `prontuarios`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/prontuarios`, { params });
 //   SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getProntuarioById(id) {
    // const cacheKey = `prontuarios-${id}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }

    const response = await axiosInstance2.get(`/prontuarios/${id}`);
  //  SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createProntuario(profissionalData) {
    SessionService.removeAll(`prontuarios`);
    if (profissionalData.id == null) {
      const response = await axiosInstance2.post('/prontuarios', profissionalData);
      return response.data;
    } else {
      return await this.updateProntuario(profissionalData.id, profissionalData);
    }

  }

  async updateProntuario(id, profissionalData) {
    SessionService.removeAll(`prontuarios`);
    const response = await axiosInstance2.put(`/prontuarios/${id}`, profissionalData);
    return response.data;

  }


  async deleteProntuario(id) {
    SessionService.removeAll(`prontuarios`);
    const response = await axiosInstance2.delete(`/prontuarios/${id}`);
    return response.data;

  }
}

export default new ProntuarioService();
