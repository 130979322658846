import * as Yup from 'yup';
import { pacienteInitialValues, pacienteValidationSchema } from './Paciente';
import { initialProfissionalValues, validationProfissionalSchema } from './Profissional';
// interface  Pessoa {
//   id: number;
//   tipo_usuario: 'Profissional' | 'Secretaria' | 'Paciente';
//   nome: string;
//   sexo?: 'M' | 'F';
//   genero?: 'Cisgênero' | 'Transgênero' | 'Não binário' | 'Agênero';
//   email?: string;
//   celular?: string;
//   telefone?: string;
//   senha?: string;
//   data_nascimento?: Date;
//   cpf?: string;
//   rg?: string;
//   cep?: string;
//   logradouro?: string;
//   complemento?: string;
//   bairro?: string;
//   uf?: string;
//   cidade?: string;
//   observacoes?: string;
//   foto?: string;
// }

const pessoaValidation= {
  nome: Yup.string().required('Campo obrigatório'),
 // tipo: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('Formato de e-mail inválido'),
  telefone: Yup.string().matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Telefone inválido'),
  celular: Yup.string().required('Campo obrigatório').matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, 'Telefone inválido'),
  data_nascimento: Yup.date().nullable(),
  cpf: Yup.string().matches(/^(\d{3}\.){2}\d{3}-\d{2}$/, 'CPF inválido'),
  cep: Yup.string().matches(/^\d{5}-?\d{3}$/, 'CEP inválido'),
  uf: Yup.string().max(2, 'UF inválida'),
  observacoes: Yup.string().max(1000, 'Observações podem ter no máximo 1000 caracteres'),

};

const pessoaValidationSchema = Yup.object().shape(pessoaValidation);
const pessoaPacienteValidationSchema = Yup.object().shape({ ...pessoaValidation, paciente:Yup.object().shape(pacienteValidationSchema)});
const pessoaProfissionalValidationSchema =Yup.object().shape({...pessoaValidation, profissional:Yup.object().shape(validationProfissionalSchema)}) ;


const pessoaInitialValues = {
  id: null,
  tipo_usuario: '',
  nome: '',
  sexo: '',
  genero: '',
  email: '',
  celular: '',
  telefone: '',
  data_nascimento: '',
  cpf: '',
  rg: '',
  cep: '',
  logradouro: '',
  complemento: '',
  bairro: '',
  uf: '',
  cidade: '',
  observacoes: '',
  foto: '',
  paciente: pacienteInitialValues,
  profissional: initialProfissionalValues
};


export { pessoaInitialValues, pessoaValidationSchema, pessoaPacienteValidationSchema, pessoaProfissionalValidationSchema  };
