import { Fragment, useEffect, useRef, useState } from "react";
import { validationSchemaAgenda } from "../../models/Agenda";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Formik, ErrorMessage, Field, Form, FieldArray } from "formik";
import InputMask from "react-input-mask";
import { Button, Col, Row, Modal, Card, Stack, ListGroup } from "react-bootstrap";

import PacienteService from "../../services/PacienteService";
import AgendaTipoService from "../../services/AgendaTipoService";
import ConvenioService from "../../services/ConvenioService";
import AgendaStatusService from "../../services/AgendaStatusService";
import AgendaService from "../../services/AgendaService";
import ProcedimentoService from "../../services/ProcedimentoService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
//import CurrencyInput from "react-currency-input-field";
const Agenda = ({ agendaInicial, state, onHide }) => {
  const [formValues, setFormValues] = useState(agendaInicial);
  
  const [pacientes, setPacientes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [convenios, setConvenios] = useState([]);
  const [situacoes, setSituacoes] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
  const [procedimentos, setProcedimentos] = useState([]);
  
  const [total, setTotal] = useState(0);
  
  const [isSearching, setIsSearching] = useState(false);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();

  const calculateTotal = (values,setFieldValue) => {
    let totalValue = 0;
    values.procedimentos.forEach((procedimento, i) => {
      let total = (parseFloat(procedimento.qtde) || 1) * (parseFloat(procedimento.valor) || 0);
      setFieldValue(`procedimentos[${i}].total`, total);
      totalValue += total;
    });
    return totalValue+(parseFloat(values.valor) || 0) ;
  };
  const reCalculateTotal = (values, i) => {
    let totalValue =  0;
    values.procedimentos.splice(i, 1);
    for (const procedimento of values.procedimentos) {
      totalValue += (parseFloat(procedimento.qtde) || 1) * (parseFloat(procedimento.valor) || 0);
    }
    return totalValue+(parseFloat(values.valor) || 0);
  };
  const handleInputChange = (event, setFieldValue, values) => {
    const { name, value } = event.target;

    // Atualiza o estado do formulário com o novo valor
    setFieldValue(name, value);

    // Calcula o total apenas se o campo alterado for "valor" ou "qtde"
    if (name === 'valor' || name.startsWith('procedimentos')) {
      setTotal(calculateTotal(values,setFieldValue));
    }
  };
  const handleProcedimentosChange = (selectedOptions, index, setFieldValue, values) => {
    //  const cleanedOptions = selectedOptions.filter((option) => option.id !== 'nova');    
    const valor = procedimentos.find(f => f.id == (parseInt(selectedOptions.target.value) || 0));
    const qtde = (values.procedimentos[index].qtde || 1);
    setFieldValue(`procedimentos[${index}].procedimento_id`, selectedOptions.target.value);
    setFieldValue(`procedimentos[${index}].valor`, valor.valor);
    setFieldValue(`procedimentos[${index}].qtde`, qtde);
    setFieldValue(`procedimentos[${index}].total`, valor.valor*qtde);
    calculateTotal(values,setFieldValue);

    
};

  const handlePacienteChange = (paciente, setFieldValue) => {
    if (!paciente.__isNew__) {
      setPacienteSelecionado(paciente);
    } else if (paciente.__isNew__) {
      paciente.id = null
      paciente.nome = paciente.value
      paciente.celular = null
      setPacienteSelecionado(paciente)
      // setIsSearching(false)
    }
    setFieldValue("paciente_id", paciente.id);
    setFieldValue("nome", paciente.nome);
    setFieldValue("celular", paciente.celular);
    
  };
  const handleIniciarConsulta = (prontuario_id,agendaId) => {
    navigate(`/prontuario/${prontuario_id}/${agendaId}`);
  };
  const handlePacienteSearch = (value) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsSearching(true);

      const fetchPacientes = async () => {
        const response = await PacienteService.getPacientes({ nome: value });
        setPacientes(response?.data || []);
        setIsSearching(false);
      };

      fetchPacientes();
    }, 300);
  };

  const remover = (agendaId) => {
    AgendaService.deleteAgenda(agendaId).then(() => {
      swal("Registro removido com sucesso!", {
        icon: "success",
      });
      state.show = false;
    })
  }
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      // console.log(values)
      if (values.paciente_id == null) {
        const pacienteResponse = await PacienteService.createPaciente({
          "id": values.paciente_id,
          "nome": values.nome,
          "celular": values.celular,
          "forceNoEmail": true
        })
        values.paciente_id = pacienteResponse.id
      }

      const agendaResponse = await AgendaService.createAgenda(values);
      toast.success("Salvo com sucesso", {
        position: "top-right",
        autoClose: 5000,
      });
      setSubmitting(false);
      //enviar para o objeto pai - se for utilizar em outros lugares, que está fora da agente, precisa refazer isso
      state.calendarEvents = [
        ...state.calendarEvents,
        {
          title: values.nome,
          start: new Date(values.data + " " + values.hora_ini),
          end: new Date(values.data + " " + values.hora_fim),
          id: agendaResponse.id,
          agenda: values
        },
      ];

      state.show = false;
    } catch (error) {
      // console.log(error)
      setSubmitting(false);
      if (error?.message) {
        toast.warning(error.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
      if (error?.errors) {
        setErrors(error.errors);
        toast.warning(error.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  };

  useEffect(() => {
    const fetchTipos = async () => {
      const response = await AgendaTipoService.getAgendaTipos({ ativo: true });
      setTipos(response);
    };

    const fetchConvenios = async () => {
      const response = await ConvenioService.getConvenios({ ativo: true });
      setConvenios(response);
    };
    const fetchStatus = async () => {
      const response = await AgendaStatusService.getAgendaStatus({
        ativo: true,
      });
      setSituacoes(response);
    };
    const fetchProcedimentos = async () => {
      const response = await ProcedimentoService.getProcedimentos();
      setProcedimentos(response);
    }
    if (formValues.id) {
      const elementos = document.querySelectorAll('.esconder');
      elementos.forEach(f => { f.style.display = 'none' });
    }
    fetchProcedimentos();
    fetchStatus();
    fetchConvenios();
    fetchTipos();
  }, []);
  return (
    <Fragment>
      <Modal show={state.show} onHide={onHide} centered size="lg">
        <Modal.Header>
          <Modal.Title>{state?.event?.agenda_tipo || 'Agendamento'}</Modal.Title>
        </Modal.Header>



        <Formik
          initialValues={formValues}
          validationSchema={validationSchemaAgenda}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleSubmit(values, { setSubmitting, setErrors });
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur,
          }) => (

            <Form>
              <Modal.Body>
                <Row>
                  <Col lg={3} className="form-group mb-1 esconder">
                    <label className="text-label">Tipo</label>
                    <Field
                      as="select"
                      name="agenda_tipo_id"
                      className={`form-control form-control-sm ${touched?.agenda_tipo_id
                        ? errors?.agenda_tipo_id
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {tipos.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nome}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="agenda_tipo_id"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </Col>
                  <Col lg={9} className="form-group mb-1 esconder">
                    <label>Paciente</label>
                    <Field
                      as={CreatableSelect}
                      name="paciente_id"
                      value={pacienteSelecionado}
                      onChange={(e) => {
                        handlePacienteChange(e, setFieldValue, values);
                      }}
                      options={pacientes}
                      getOptionLabel={(paciente) => paciente.nome}
                      getOptionValue={(paciente) => paciente.id}
                      isLoading={isSearching}
                      isSearchable
                      className={`custom-select custom-select-sm ${touched?.paciente_id
                        ? errors?.paciente_id
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                      onInputChange={handlePacienteSearch}
                    />
                    <ErrorMessage
                      name="paciente_id"
                      component="div"
                      className="invalid-feedback animated fadeInUp error"
                      style={{ display: "block" }}
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Celular</label>
                    <Field
                      size="sm"
                      as={InputMask}
                      mask="(99) 99999-9999"
                      name="celular"
                      type="text"
                      className={`form-control form-control-sm ${touched.celular
                        ? errors.celular
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="celular"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={8} className="form-group mb-1">
                    <label>Nome</label>
                    <Field
                      type="text"
                      name="nome"
                      value={values.nome}
                      className={`form-control form-control-sm ${touched.nome
                        ? errors.nome
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="nome"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Convênio</label>
                    <Field
                      component="select"
                      name="convenio_id"
                      value={values.convenio_id}
                      className={`form-control form-control-sm ${touched.convenio_id
                        ? errors.convenio_id
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    >
                      <option value="">Selecione um convênio</option>
                      {convenios.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nome}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="convenio_id"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Data</label>
                    <Field
                      type="date"
                      name="data"
                      className={`form-control form-control-sm ${touched.data
                        ? errors.data
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="data"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Hora de início</label>
                    <InputMask
                      mask="99:99"
                      value={values.hora_ini}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="hora_ini"
                      className={`form-control form-control-sm ${touched.hora_ini
                        ? errors.hora_ini
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="hora_ini"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Hora de fim</label>
                    <InputMask
                      mask="99:99"
                      value={values.hora_fim}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="hora_fim"
                      className={`form-control form-control-sm ${touched.hora_fim
                        ? errors.hora_fim
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="hora_fim"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Situação</label>
                    <Field
                      component="select"
                      name="agenda_status_id"
                      value={values.agenda_status_id}
                      className={`form-control form-control-sm ${touched.agenda_status_id
                        ? errors.agenda_status_id
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    >
                      {situacoes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nome}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="agenda_status_id"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={4} className="form-group mb-1">
                    <label>Valor</label>
                    <Field 
                      name="valor"
                            //  value={values.valor}
                            //  component={CurrencyInput}
                              placeholder="R$ 0,00"
                              prefix=""
                              decimalSeparator=","
                              thousandSeparator="."
                              allowNegativeValue={false}
                              // onValueChange={(value, name) => {
                              //   // O Formik precisa do valor numérico (sem formatação)
                              //   setFieldValue(name, parseFloat(value) || 0);
                              // }}
                      onBlur={(event) => handleInputChange(event, setFieldValue, values)}
                      className={`form-control form-control-sm ${touched.valor
                        ? errors.valor
                          ? "is-invalid"
                          : "is-valid"
                        : ""
                        }`}
                    />

                    <ErrorMessage
                      name="valor"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col lg={12} className="form-group mb-1">
                    <label>Observações</label>
                    <Field
                      type="text"
                      name="observacoes"
                      value={values.observacoes}
                      className={`form-control form-control-sm`}
                    />

                    <ErrorMessage
                      name="observacoes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  </Row>
                  {procedimentos && procedimentos.length > 0 && (
                  <Card  className='card'> 
                              <div className='card-header border-1 pl-1 mb-0'>
                              <h6 className='card-title'>Procedimentos</h6>
                            </div>
                            <Card.Body>
                  

                          
                    <FieldArray name="procedimentos">
                      {({ push, remove }) => (
                        <div>
                          {values.procedimentos.map((_, index) => (
                       

                             
                                <Row key={index}>
                                  <Col lg={5} className="form-group m-0 p-1">
                                    <Field
                                      component="select"
                                      name={`procedimentos[${index}].procedimento_id`} 
                                      value={values.procedimentos[index].procedimento_id}
                                      className={`form-control form-control-sm`}
                                      onChange={(e) => {
                                        handleProcedimentosChange(e,index, setFieldValue,values );
                                      }}
                                      onBlur={(event) => handleInputChange(event, setFieldValue, values)}
                                    >
                                      <option value="">Selecione um convênio</option>
                                      {procedimentos.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.nome}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name={`procedimentos[${index}].procedimento_id`}
                                      component="div"
                                      className="invalid-feedback"
                                    />

                                  </Col>
                                  <Col lg={2} className="form-group m-0 p-1">
                                    <Field name={`procedimentos[${index}].qtde`} min={1} type="number" className={`form-control form-control-sm`}
                                   // onChange={(e) => handleQtdeChange(e,index, setFieldValue,values.procedimentos[index].valor )} 
                                   onBlur={(event) => handleInputChange(event, setFieldValue, values)}
                                    />
                                    <ErrorMessage
                                      name={`procedimentos[${index}].qtde`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col lg={2} className="form-group m-0 p-1">
                                    <Field name={`procedimentos[${index}].valor`} type="number" className={`form-control form-control-sm`} 
                                       // onChange={(e) => handleValorChange(e,index, setFieldValue,values.procedimentos[index].qtde )}
                                       onBlur={(event) => handleInputChange(event, setFieldValue, values)}
                                        
                                        />
                                    <ErrorMessage
                                      name={`procedimentos[${index}].valor`}
                                  
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col lg={2} className="form-group m-0 p-1">
                                    <Field name={`procedimentos[${index}].total`} 
                                    value={values.procedimentos[index].total} type="number" className={`form-control form-control-sm`} readOnly={true} />
                                
                                  </Col>
                                  <Col lg={1} className="m-0 p-1">
                                    <Button variant="danger" size="sm"  onClick={() =>
                                      swal({
                                        title: "Confirma a exclusão?",
                                        text: "",
                                        icon: "warning",
                                        buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                        dangerMode: true,

                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          remove(index);
                                          setTimeout(function(){
                                            setTotal(reCalculateTotal(values, index));
                                          }, 500);                                        

                                        }
                                      })
                                    }>
                                      X
                                    </Button>
                                  </Col>
                                </Row>
                          
                          
                          ))}
                              <Stack direction="horizontal" gap={2} className="mt-3">
                              <Button variant="success" size="sm" className="btn-block" onClick={() => push({ procedimento_id: '', qtde: 1, valor: 0.00, total: 0.00 })}>
                            Adicionar Procedimento
                          </Button>
      <div className="vr" />
      <small>Total:</small><h3>{total}</h3>
    </Stack>
                         
                          
                        </div>
                      )}
                    </FieldArray>        
              
                 
                      </Card.Body>     
                    </Card>
                  )}
                  <Row>
                  <Col lg={4}>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="primeiro_atend"
                        className="form-check-input"
                      />
                      <label className="form-check-label">
                        Primeiro atendimento
                      </label>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="form-check">
                      <Field
                        type="checkbox"
                        name="telemedicina"
                        className="form-check-input"
                      />
                      <label className="form-check-label">Telemedicina</label>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-primary" size="sm" onClick={onHide}>
                  Fechar
                </Button>
                {values?.id &&
                  <Button variant="danger" size="sm" onClick={() =>
                    swal({
                      title: "Confirma a exclusão?",
                      text: "",
                      icon: "warning",
                      buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                      dangerMode: true,

                    }).then((willDelete) => {
                      if (willDelete) {
                        remover(values.id);
                        
                      }
                    })
                  }>
                    Excluir
                  </Button>}
                {values?.id &&
                  <Button variant="info" size="sm" onClick={() => handleIniciarConsulta(values?.prontuario_id, values?.id)} >
                    Iniciar Consulta
                  </Button>
                }
                <Button type="submit" variant="primary" disabled={isSubmitting} size="sm">
                  {isSubmitting ? "Agendando..." : "Agendar"}
                </Button>
              </Modal.Footer>
            </Form>

          )}
        </Formik>


      </Modal>

    </Fragment>
  );
};

export default Agenda;
