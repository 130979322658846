
import { Fragment, useEffect, useState } from "react";
import SecaoService from "../../services/SecaoService";
import { createSlug } from "../../helper/Helpers";

const SecaoDetalhes = ({ interacoes }) => {
    const [secoes, setSecoes] = useState([]);
    const loadSecoes = async () => {
        const data = await SecaoService.getSecoes({});
        setSecoes(data);
    }

    const tratarTexto = (interacao) => {
        let resultados = JSON.parse(interacao.descricao);
        const secao = secoes.find(s => s.id === interacao.secao_id);
        let formulario = JSON.parse(secao?.formulario || "[]");

        let retorno = [];

        for (const key in formulario) {
            retorno.push({ titulo: formulario[key].titulo, valor: resultados[createSlug(formulario[key].titulo)] });
        }
        return retorno;
    };


    useEffect(() => {
        loadSecoes();

    }, []);
    return (
        <Fragment>
            {secoes.length > 0 && (interacoes.map(m => (
                <div className='card m-1'>
                    <div className='card-header border-0 pb-0'>
                        <h4 className='card-title'>{m.secao?.nome || "Secão removida"}</h4>
                    </div>
                    <div className='card-body'>
                        <div className="p-2"  style={{  border: '1px dashed #dee2e6' }}>
                        {tratarTexto(m).map(t => (<div className="mb-2"><div className="mb-1" style={{ fontWeight: '500', fontSize:'12px'}}>{t.titulo}</div><div dangerouslySetInnerHTML={{ __html: t.valor }} style={{ padding: '5px',border: '1px solid #ecebeb', background: '#fdf9f9', minHeight: '36px'}} ></div></div>))}
                        </div>
                    </div>
                </div>
            )))}
        </Fragment>
    )
}
export default SecaoDetalhes;