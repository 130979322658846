import * as yup  from 'yup';

const validationSchemaAgenda = yup.object().shape({
    agenda_status_id: yup.number().integer().positive().required(),
    //profissional_id: yup.number().integer().positive().required(),
    //sala_id: yup.number().integer().positive().required(),
    // paciente_id: yup
    //   .number()
    //   .integer()
    //   .positive()
    //   .required()
    //   .test(
    //     "is-patient",
    //     "O paciente deve existir e ser do tipo Paciente",
    //     (value) => {
    //       // Aqui você pode fazer uma chamada à API ou verificar em um banco de dados se o paciente existe e é do tipo Paciente
    //       return true; // Substitua por sua lógica de validação
    //     }
    //   ),
   // convenio_id: yup.number().integer().positive().required(),
   // paciente_id: yup.number().integer().positive("Paciente inválido").required("Escolha um paciente"),
    nome: yup.string().max(100).required("Nome inválido"),
    celular: yup.string().max(20).required("Celular inválido"),
    data: yup.date().required("Data inválida"),
    hora_ini: yup.string().max(5).required("Hora inválida"),
    hora_fim: yup.string().max(5,"Hora inválida").required("Hora inválida"),
    valor: yup.number("Valor inválido") //.positive() //.required("Valor inválido"),
  });
  
  const initialValuesAgenda = {
    agenda_status_id: 1,
    profissional_id: 1,
    agenda_tipo_id: 1,
    sala_id: null,
    paciente_id: null,
    convenio_id: null,
    nome: "",
    celular: "",
    primeiro_atend: false,
    enviar_msg: false,
    telemedicina: false,
    data: null,
    hora_ini: null,
    hora_fim: null,
    valor: 0,
    observacoes: '',
    procedimentos: [] //{ procedimento_id: null, qtde: 1, valor: 0 }
  };

  export  { initialValuesAgenda, validationSchemaAgenda };