import * as Yup from 'yup';
const initialValues = {
    id: null,
    procedimento_tipo_id: 0,
    nome: '',
 //   cor: '#000000', // Valor padrão para o tipo color
    duracao_min: 0,
    valor: '0.00',
  };

  const validationSchema = Yup.object().shape({
    procedimento_tipo_id: Yup.number().required('Campo obrigatório'),
    nome: Yup.string().required('Campo obrigatório').max(255, 'Máximo de 255 caracteres'),
 //   cor: Yup.string().required('Campo obrigatório'),
    duracao_min: Yup.number().required('Campo obrigatório').min(1, 'Mínimo de 1 minuto'),
    valor: Yup.number().required('Campo obrigatório').min(0, 'Mínimo de 0.00').max(9999999.99, 'Máximo de 9,999,999.99'),
  });

  export {initialValues,  validationSchema };