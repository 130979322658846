const replaceNullsWithEmpty = (obj) => {
  // Verifica se o argumento é um objeto
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Percorre todas as chaves do objeto
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Verifica se o valor da chave é null
      if (obj[key] === null) {
        obj[key] = ''; // Substitui null por ''
      } else if (typeof obj[key] === 'object') {
        // Se o valor da chave é um objeto, chama a função recursivamente
        obj[key] = replaceNullsWithEmpty(obj[key]);
      }
    }
  }

  return obj;
}

export default replaceNullsWithEmpty;