import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';
class EspecidadeService {

  async getEspecidades(params) {
    const cacheKey = `especialidades-${params['profissao_id']}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
       return cachedData;
     }
      const response = await axiosInstance2.get(`/especialidades`,{ params });
      SessionService.addToCache(cacheKey, response.data);
      return response.data;

  }


  async getEspecidadeById(id) {
      const response = await axiosInstance2.get(`/especialidades/${id}`);
      return response.data;

  }


  async createEspecidade(profissionalData) {
    if(profissionalData.id == null){
      const response = await axiosInstance2.post('/especialidades', profissionalData);
      return response.data;
    }else{
        return  await this.updateEspecidade(profissionalData.id, profissionalData);
    }

  }

  async updateEspecidade(id, profissionalData) {
      const response = await axiosInstance2.put(`/especialidades/${id}`, profissionalData);
      return response.data;

  }

 
  async deleteEspecidade(id) {
      const response = await axiosInstance2.delete(`/especialidades/${id}`);
      return response.data;

  }
}

export default new EspecidadeService();
