import React, { useState, useEffect } from "react";
import SalaService from "../../services/SalaService";
import {  Button, Col, Modal, Row, Table } from "react-bootstrap";
import * as Yup from 'yup';
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";

const Salas = () => {
    const [data, setData] = useState([]);
    const [salaOpen, setSalaOpen] = useState(false);
    const [sala, setSala] = useState({ id: null, nome: '', ativo: true });
    const loadData = async () => {
        const data = await SalaService.getSalas({});
        setData(data);
    }

    const remover = (salaId) => {
        SalaService.deleteSala(salaId).then(() => {
            loadData();
            swal("Registro removido com sucesso!", {
                icon: "success",
            });

        })
    }

    const editar = (data) => {
        setSalaOpen(true);
        setSala(data);
    }

    const novo = () => {
        setSala({ id: null, nome: '', ativo: true });
        setSalaOpen(true);
    }

    const salvar = (values, { setSubmitting, setErrors }) => {
        SalaService.createSala(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            setSalaOpen(false);
            loadData();
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="form-head d-flex mb-1 md-2 align-items-start">
                        <div className="me-auto d-none d-lg-block">
                            <Button onClick={() => novo()}
                                className="btn btn-primary btn-rounded"
                            >
                                + Novo
                            </Button>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">

                            <Table responsive bordered striped className="verticle-middle">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">
                                            Nome
                                        </th>
                                        <th scope="col">
                                            Ativo
                                        </th>
                                        <th scope="col" style={{ width: 120 }}>Ação</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((sala, i) => (
                                        <tr role="row" key={i}>
                                            <td >{sala.nome}</td>
                                            <td> {sala.ativo ? (<i className="fa fa-check  text-primary"></i>) : (<i className="fa fa-cancel  text-danger"></i>)}</td>
                                            <td role="row" >

                                                <span>
                                                    <Link className="me-3" onClick={() => { editar(sala) }}><i className="fa fa-pencil  color-muted"></i></Link>

                                                    <Link className="me-3" onClick={() =>
                                                        swal({
                                                            title: "Confirma a exclusão?",
                                                            text: "",
                                                            icon: "warning",
                                                            buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                            dangerMode: true,

                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                remover(sala.id)
                                                            }
                                                        })
                                                    } ><i className="fa fa-trash  text-danger"></i></Link>
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Col>
            </Row>

            <Modal
                className="fade"
                show={salaOpen}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Sala</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setSalaOpen(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        enableReinitialize={true}
                        initialValues={sala}
                        validationSchema={Yup.object().shape({
                            nome: Yup.string().required('Campo obrigatório')
                        })
                        }
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            salvar(values, { setSubmitting, setErrors });
                        }}
                    >
                        {({ values, handleChange, handleBlur, touched, errors }) => (
                            <Form>
                                <div className='row'>

                                    <div className="form-group mb-2 col-lg-12">
                                        <label className="text-label">Nome</label>
                                        <Field
                                            type="text"
                                            name="nome"
                                            className={`form-control form-control -sm ${touched.nome && errors.nome ? 'is-invalid' : 'is-valid'}`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nome}
                                        />
                                        <ErrorMessage name="nome" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-3">
                                        <label className="text-label mb-1 me-4 " htmlFor="ativo">Ativo</label>
                                        <Field
                                            type="checkbox"
                                            name="ativo"
                                            className={`form-check-input me-2 mt-0 ${touched.ativo && errors.ativo ? 'is-invalid' : 'is-valid'}`}

                                        />
                                        <ErrorMessage name="ativo" component="div" className="invalid-feedback" />
                                    </div>

                                </div>
                                {/* Continue com os campos restantes do formulário, seguindo o mesmo padrão */}

                                <div className="form-head d-flex mb-1 md-1 align-items-start">
                                    <button type="submit" className="btn btn-primary ms-auto d-inline-flex me-1">Salvar</button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default Salas;
