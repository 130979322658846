import  { axiosInstance2 } from '../services/AxiosInstance2';

class EmpresaService {

  async getEmpresas() {
      const response = await axiosInstance2.get('/empresas');
      return response.data;

  }


  async getEmpresaById(id) {
      const response = await axiosInstance2.get(`/empresas/${id}`);
      return response.data;

  }


  async createEmpresa(empresaData) {
    if(empresaData.id == null){
      const response = await axiosInstance2.post('/empresas', empresaData);
      return response.data;
    }else{
        return  await this.updateEmpresa(empresaData.id, empresaData);
    }

  }

  async updateEmpresa(id, empresaData) {
      const response = await axiosInstance2.put(`/empresas/${id}`, empresaData);
      return response.data;

  }

 
  async deleteEmpresa(id) {
      const response = await axiosInstance2.delete(`/empresas/${id}`);
      return response.data;

  }
}

export default new EmpresaService();
