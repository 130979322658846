import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class EscolaridadeService {

  async getEscolaridades(params) {

    const cacheKey = `escolaridades`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
    const response = await axiosInstance2.get(`/escolaridades`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getEscolaridadeById(id) {
    const cacheKey = `escolaridades-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/escolaridades/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createEscolaridade(data) {
    SessionService.removeAll(`escolaridades`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/escolaridades', data);
      return response.data;
    } else {
      return await this.updateEscolaridade(data.id, data);
    }

  }

  async updateEscolaridade(id, data) {
    SessionService.removeAll(`escolaridades`);
    const response = await axiosInstance2.put(`/escolaridades/${id}`, data);
    return response.data;

  }


  async deleteEscolaridade(id) {
    SessionService.removeAll(`escolaridades`);
    const response = await axiosInstance2.delete(`/escolaridades/${id}`);
    return response.data;

  }
}

export default new EscolaridadeService();
