//import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PessoaForm from "../../../components/Pessoa/PessoaForm";
import { Tab, Nav } from 'react-bootstrap';
import DoctorAgenda from "./DoctorAgenda";

const DoctorEdit = () => {
    const { id } = useParams();

    return (
        <>
            <div className="h-70 ms-0">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/profissionais">Profissional</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >Salvar</Link>
                        </li>
                    </ol>
                </div>

                <div className="card">
                    <div className="card-body">

                        <Tab.Container defaultActiveKey={0} id="doctor-edit">
                            <Nav as='ul' className='nav-pills mb-4 justify-content-end'>

                                <Nav.Item as='li' key={0} >
                                    <Nav.Link eventKey="0" style={{float: "left"}}>
                                        Dados Pessoais
                                    </Nav.Link>
                                    <Nav.Link eventKey="1" style={{float: "left"}}>
                                        Horarios de atendimento
                                    </Nav.Link>
                                </Nav.Item>

                            </Nav>
                            <Tab.Content className='pt-4'>
                                <Tab.Pane eventKey="0" key={0}>
                                    <PessoaForm pessoa={{ tipo: 'Profissional', id: id }} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="1"  key={1}>
                                      <DoctorAgenda />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>

                </div>
            </div>

        </>
    );
};

export default DoctorEdit;
