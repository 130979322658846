import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class FeriadoService {

  async getFeriados(params) {

     const cacheKey = params? `feriados-${params?.nome}`: `feriados`;
     const cachedData = SessionService.getCachedData(cacheKey);
     if (cachedData) {
       return cachedData;
     }
    const response = await axiosInstance2.get(`/feriados`, { params });
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getFeriadoById(id) {
    const cacheKey = `feriados-${id}`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }

    const response = await axiosInstance2.get(`/feriados/${id}`);
    SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createFeriado(data) {
    SessionService.removeAll(`feriados`);
    if (data.id == null) {
      const response = await axiosInstance2.post('/feriados', data);
      return response.data;
    } else {
      return await this.updateFeriado(data.id, data);
    }

  }

  async updateFeriado(id, data) {
    SessionService.removeAll(`feriados`);
    const response = await axiosInstance2.put(`/feriados/${id}`, data);
    return response.data;

  }


  async deleteFeriado(id) {
    SessionService.removeAll(`feriados`);
    const response = await axiosInstance2.delete(`/feriados/${id}`);
    return response.data;

  }
}

export default new FeriadoService();
