import * as Yup from 'yup';

export const validatePessoa = (tipo, value) => {

    let error = "";
    if (tipo && value) {
        if(tipo === 'PF'){
            return Yup.string().replace(/[^\d]/g, '').matches(/^\d{11}$/, 'CPF inválido');
        }else if(tipo === 'PJ'){
            return Yup.string().replace(/[^\d]/g, '').matches(/^\d{14}$/, 'CNPJ inválido')
        }
        return error;
    }
    return error;
  };


// export interface Empresa {
//     id?: number;
//     tipo: 'PF' | 'PJ';
//     nome: string;
//     email: string;
//     telefone: string;
//     razao_social?: string | '';
//     cpf_cnpj?: string | '';
//     inscricao_municipal?: string | '';
//     cnes?: string | '';
//     cep?: string | '';
//     logradouro?: string | '';
//     complemento?: string | '';
//     bairro?: string | '';
//     uf?: string | '';
//     cidade?: string | '';
//     foto?: string | '';
//     ativo: boolean;
// }

export const EmpresaValidationSchema = Yup.object().shape({
    tipo: Yup.string().required('Campo obrigatório'),
    nome: Yup.string().required('Campo obrigatório'),
    email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
    telefone: Yup.string().required('Campo obrigatório'),
    cpf_cnpj: Yup.string().required('Campo obrigatório'),
    cep: Yup.string().matches(/^\d{5}-\d{3}$/, 'CEP inválido'),

});

export const initialValues = {
    id: null,
    tipo: 'PF',
    nome: '',
    email: '',
    telefone: '',
    razao_social: '',
    cpf_cnpj: '',
    inscricao_municipal: '',
    cnes: '',
    cep: '',
    logradouro: '',
    complemento: '',
    bairro: '',
    uf: '',
    cidade: '',
    foto: '',
    ativo: true,
};

