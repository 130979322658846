import React from 'react';
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({ totalPages, activePage, onPageClick, itemsPerPage }) => {
  const pages = Math.ceil(totalPages / itemsPerPage);
  const paginationItems = [];
  for (let number = 1; number <= pages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === activePage}
        onClick={() => onPageClick(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (

    <div className="d-sm-flex text-center justify-content-between align-items-center">
       <div
                                 className="dataTables_info"
                                 id="example5_info"
                                 role="status"
                                 aria-live="polite"
                              >
                                {/* todo: adicionar descricão */}
                                </div>
      <div className="d-flex justify-content-between align-items-center">
        {/* <div>
        Showing {activePage * itemsPerPage } to{' '}
        {Math.min(totalPages, totalPages)}{' '}
        of {totalPages} entries
      </div> */}
        <Pagination>{paginationItems}</Pagination>
      </div>
    </div>
  );
};

export default CustomPagination;
