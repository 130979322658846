//import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PessoaForm from "../../../components/Pessoa/PessoaForm";

const PatientNew = () => {
    const { id } = useParams();

    return (
        <>
            <div className="h-70 ms-0">
                <div className="page-titles">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to="/pacientes">Pacientes</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >Salvar</Link>
                        </li>
                    </ol>
                </div>
                <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
                    <div className="card">
                        <div className="card-body">
                            <PessoaForm pessoa={{ tipo: 'Paciente', id: id }} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientNew;
