import React, { useState, useEffect } from "react";
import FeriadoService from "../../services/FeriadoService";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import * as Yup from 'yup';
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";

const Feriados = () => {
    const [data, setData] = useState([]);
    const [feriadoOpen, setFeriadoOpen] = useState(false);
    const [feriado, setFeriado] = useState(null);
    const [filter, setFilter] = useState(null);
    const loadData = async () => {
        const data = await FeriadoService.getFeriados({nome: filter});
        setData(data);
    }

    const remover = (feriadoId) => {
        FeriadoService.deleteFeriado(feriadoId).then(() => {
            loadData();
            swal("Registro removido com sucesso!", {
                icon: "success",
            });

        })
    }

    const editar = (data) => {
        setFeriadoOpen(true);
        setFeriado(data);
    }

    const novo = () => {
        setFeriado({ id: null, nome: '', descricao: '', tipo: '', data: '' });
        setFeriadoOpen(true);
    }

    const salvar = (values, { setSubmitting, setErrors }) => {
        FeriadoService.createFeriado(values).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            setFeriadoOpen(false);
            loadData();
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className="form-head d-flex mb-1 md-2 align-items-start">
                        <div className="me-auto d-none d-lg-block">
                            <Button onClick={() => novo()}
                                className="btn btn-primary btn-rounded"
                            >
                                + Novo
                            </Button>
                        </div>
                        <div className="input-group search-area ms-auto d-inline-flex me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar.."
                                value={filter}
                                onChange={(e) => { setFilter(e.target.value) }}
                            />
                            <div className="input-group-append">
                                <button type="button" className="input-group-text" onClick={() => { loadData(); }}>
                                    <i className="flaticon-381-search-2" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">

                            <Table responsive bordered striped className="verticle-middle">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">
                                            Data
                                        </th>
                                        <th scope="col">
                                            Nome
                                        </th>
                                        <th scope="col">
                                            Tipo
                                        </th>
                                        <th scope="col" style={{ width: 120 }}>Ação</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((feriado, i) => (
                                        <tr role="row" key={i}>
                                            <td >{moment(feriado.data).format('DD/MM/YYYY')}</td>
                                            <td >{feriado.nome}</td>
                                            <td> {feriado.tipo}</td>
                                            <td role="row" >

                                                <span>
                                                    <Link className="me-3" onClick={() => { editar(feriado) }}><i className="fa fa-pencil  color-muted"></i></Link>

                                                    <Link className="me-3" onClick={() =>
                                                        swal({
                                                            title: "Confirma a exclusão?",
                                                            text: "",
                                                            icon: "warning",
                                                            buttons: { cancel: 'Cancelar', confirm: 'Confirmar' },
                                                            dangerMode: true,

                                                        }).then((willDelete) => {
                                                            if (willDelete) {
                                                                remover(feriado.id)
                                                            }
                                                        })
                                                    } ><i className="fa fa-trash  text-danger"></i></Link>
                                                </span>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Col>
            </Row>

            <Modal
                className="fade"
                show={feriadoOpen}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Feriado</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setFeriadoOpen(false)}
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        enableReinitialize={true}
                        initialValues={feriado}
                        validationSchema={Yup.object().shape({
                            data: Yup.string().required('Campo obrigatório'),
                            nome: Yup.string().required('Campo obrigatório'),
                            descricao: Yup.string().required('Campo obrigatório'),
                            tipo: Yup.string().required('Campo obrigatório'),
                        })
                        }
                        onSubmit={(values, { setSubmitting, setErrors }) => {
                            salvar(values, { setSubmitting, setErrors });
                        }}
                    >
                        {({ touched, errors }) => (
                            <Form>
                                <div className='row'>
                                    <div className="form-group mb-1 col-lg-4">
                                        <label className="text-label">Data</label>
                                        <Field
                                            type="date"
                                            name="data"
                                            className={`form-control form-control-sm ${touched.data && errors.data ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="data" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-8">
                                        <label className="text-label">Nome</label>
                                        <Field
                                            type="text"
                                            name="nome"
                                            className={`form-control form-control-sm ${touched.nome && errors.nome ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="nome" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-12">
                                        <label className="text-label">Descrição</label>
                                        <Field
                                            type="text"
                                            name="descricao"
                                            className={`form-control form-control-sm ${touched.descricao && errors.descricao ? 'is-invalid' : 'is-valid'}`}
                                        />
                                        <ErrorMessage name="descricao" component="div" className="invalid-feedback" />
                                    </div>
                                    <div className="form-group mb-1 col-lg-12">
                                        <label className="text-label">Tipo</label>
                                        <Field
                                            as="select"
                                            className={`form-control form-control-sm ${touched.tipo && errors.tipo ? 'is-invalid' : 'is-valid'}`}
                                            id="tipo"
                                            name="tipo"
                            
                                        >
                                            <option value="" >Selecione..</option>
                                            <option value="Feriado Municipal">Feriado Municipal</option>

                                        </Field>
                               
                                        <ErrorMessage name="tipo" component="div" className="invalid-feedback" />
                                    </div>


                                </div>
                                {/* Continue com os campos restantes do formulário, seguindo o mesmo padrão */}

                                <div className="form-head d-flex mb-1 md-1 align-items-start">
                                    <button type="submit" className="btn btn-primary ms-auto d-inline-flex me-1 btn-sm">Salvar</button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default Feriados;
