import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputMask from 'react-input-mask';




const DynamicInput = ({ type, titulo, name, opcoes, values ,order }) => {
    let campo;
    let colSm = '6';

    switch (type) {
        case 'number':
        case 'date':
            colSm = '3';
            campo = <Field name={name} type={type} className="form-control form-control-sm " />;
            break;
        case 'hora':
            colSm = '3';
            campo = <Field
                type="text"
                name={name}
                className={`form-control  form-control-sm`}
                as={InputMask}
                mask="99:99"
            />
            break;

        case 'select':
            colSm = '6';
            campo = (
                <Field name={name} as="select" className="form-control form-control-sm ">
                    {opcoes.map((opcao, index) => (
                        <option key={index} value={opcao.valor}>
                            {opcao.label}
                        </option>
                    ))}
                </Field>
            );
            break;
        case 'textoRico':
            colSm = '12';
            campo = <Field name={name}>
                {({ field }) => (
                    <CKEditor
                     name={name}
                        config={{
                            toolbar: ['heading',
                                '|', 'bold', 'italic',
                                '|', 'alignment',
                                '|', 'link', 'insertTable',
                                '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                                '|', 'undo', 'redo',]
                        }}
                        editor={ClassicEditor}
                        data={field.value}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            field.onChange({ target: { value: data || "", name: field.name } });
                         }}
                     />
                )}
            </Field>
            break;
        case 'radio':
            colSm = '12';
            campo = (
                opcoes.map((option, index) => (
                    <div className="form-check" key={index}>
                        <Field
                            key={index}
                            className="form-check-input"
                            type="radio"
                            name={`${name}`}
                            id={`opt-${index}`}
                            label={option.label}
                            value={option.label}
                        />
                        <label className="form-check-label">
                            {option.label}
                        </label>
                    </div>
                )));
            break;
        case 'checkbox':
            colSm = '12';
            campo = (
                opcoes.map((checkbox, index) => (
                    <div className="form-check mb-2" key={index}>
                        <Field type="checkbox" id={`checkbox-${index}`} name={`${name}`} value={checkbox.label} className="form-check-input" />
                        <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                            {checkbox.label}
                        </label>
                    </div>
                ))
            );
            break;
        default:
            colSm = '6';
            campo = <Field name={name} type="text" className="form-control form-control-sm " />;
            break;
    }

    return (
        <div className={`col-sm-${colSm}`}>
            <Form.Group as={Col}  className='mt-3 mb-3'>
                <Form.Label>{titulo}</Form.Label>
                {campo}
                <ErrorMessage name={name} component="div" className="text-danger" />
            </Form.Group>
        </div>
    );
};

export default DynamicInput;
