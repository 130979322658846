import  { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class EmpresaConfiguracaoService {

  async getEmpresaConfiguracaos() {
    const cacheKey = `empresa-configuracao`;
    const cachedData = SessionService.getCachedData(cacheKey);
    if (cachedData) {
      return cachedData;
    }
      const response = await axiosInstance2.get('/empresa-configuracao');
      SessionService.addToCache(cacheKey, response.data);
      return response.data;

  }


  async getEmpresaConfiguracaoById(id) {
      const response = await axiosInstance2.get(`/empresa-configuracao/${id}`);
      return response.data;

  }


  async createEmpresaConfiguracao(empresaConfiguracaoData) {
    if(empresaConfiguracaoData.id == null || empresaConfiguracaoData.id == 0){
      const response = await axiosInstance2.post('/empresa-configuracao', empresaConfiguracaoData);
      return response.data;
    }else{
        return  await this.updateEmpresaConfiguracao(empresaConfiguracaoData.id, empresaConfiguracaoData);
    }
      

  }

  async updateEmpresaConfiguracao(id, empresaConfiguracaoData) {
    SessionService.removeAll(`empresa-configuracao`);
      const response = await axiosInstance2.put(`/empresa-configuracao/${id}`, empresaConfiguracaoData);
      return response.data;

  }

 
  async deleteEmpresaConfiguracao(id) {
    SessionService.removeAll(`empresa-configuracao`);
      const response = await axiosInstance2.delete(`/empresa-configuracao/${id}`);
      return response.data;

  }
}

export default new EmpresaConfiguracaoService();
