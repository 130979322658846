import * as Yup from 'yup';

// Objeto de inicialização para o Formik
const initialProfissionalValues = {
  pessoa_id: 0,
  profissao_id: 0,
  agenda_online: 0,
  tratamento: '',
  nome_conselho: '',
  numero_conselho: '',
  rqe: '',
  cnes: '',
  uf_conselho: '',
  link_site: '',
  link_facebook: '',
  link_youtube: '',
  link_instagram: '',
  especialidades: []
};

// Objeto de validação usando o Yup
const validationProfissionalSchema = {
  profissao_id: Yup.number().required('Campo obrigatório'),
  tratamento: Yup.string().required('Campo obrigatório').max(10, 'Máximo de 10 caracteres'),
  nome_conselho: Yup.string().required('Campo obrigatório'),
  numero_conselho: Yup.string().required('Campo obrigatório'),
  rqe: Yup.string().max(20, 'Máximo de 20 caracteres'),
  cnes: Yup.string().max(20, 'Máximo de 20 caracteres'),
  uf_conselho: Yup.string().max(2, 'Máximo de 2 caracteres'),
  link_site: Yup.string().url('Formato de URL inválido'),
  link_facebook: Yup.string().url('Formato de URL inválido'),
  link_youtube: Yup.string().url('Formato de URL inválido'),
  link_instagram: Yup.string().url('Formato de URL inválido'),
};

export { initialProfissionalValues, validationProfissionalSchema };
