import React from 'react'
import { connect } from 'react-redux';
import { Link , useNavigate} from 'react-router-dom'

import * as Yup from 'yup';
//
import logo from '../../images/logo.png'
import logotext from '../../images/logo-text.png'
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import {
    login,
    saveTokenInLocalStorage,
} from '../../services/AuthService';
import { confirmedSignupAction } from '../../store/actions/AuthActions';

function Login () {
	const validateSchema =  Yup.object().shape({
		email: Yup.string().email('Formato de e-mail inválido').required('Campo obrigatório'),
		senha: Yup.string().required('Campo obrigatório'),
	});
	const navigate = useNavigate();
    function onLogin(values, { setSubmitting, setErrors }) {
		
	 login(values.email, values.senha).then(data => {
			saveTokenInLocalStorage(data.data);
			confirmedSignupAction(data.data)
            toast.success("Login efetuado com sucesso com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
			navigate('/dashboard');
        }).catch(error => {
            if (error?.response?.data?.errors) {
              setErrors(error?.response?.data?.errors);
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
        // let error = false;
        // const errorObj = { ...errorsObj };
        // if (email === '') {
        //     errorObj.email = 'Email is Required';
        //     error = true;
        // }
        // if (password === '') {
        //     errorObj.password = 'Password is Required';
        //     error = true;
        // }
        // setErrors(errorObj);
        // if (error) {
		// 	return ;
		// }
		//dispatch(loadingToggleAction(true));	
       // dispatch(loginAction(email, password, navigate));
    }

  return (

		<div className="login-form-bx">
				<ToastContainer />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-6 col-md-7 box-skew d-flex">
						<div className="authincation-content">
							<Link to="#" className="login-logo">
								<img src={logo} alt="" className="logo-icon me-2"/>
								<img src={logotext} alt="" className="logo-text ms-1"/>
							</Link>
							<div className="mb-4">
								<h3 className="mb-1 font-w600">Bem vindo a Innoviclinic</h3>
								<p className="">Faça login inserindo as informações abaixo</p>
							</div>
							
							<Formik
                    initialValues={{email:'', senha:''}}
                    validationSchema={validateSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        setTimeout(() => {
                            onLogin(values, { setSubmitting, setErrors });
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        touched,
                        errors,
                    }) => (
                        <Form>  
								<div className="form-group">
									<label className="mb-2 ">
										<strong className="">Email</strong>
									</label>
									<Field
                                        type="email"
                                        name="email"
                                        className={`form-control ${touched?.email ? (errors.email ? 'is-invalid' : 'is-valid') : ''}`}

                                    />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
									{/* <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>
									{errors.email && <div className="text-danger fs-12">{errors.email}</div>} */}
								</div>
								<div className="form-group">
									<label className="mb-2 "><strong className="">Senha</strong></label>
									<Field
                                        type="password"
                                        name="senha"
                                        className={`form-control ${touched?.senha ? (errors.senha ? 'is-invalid' : 'is-valid') : ''}`}

                                    />
                                    <ErrorMessage name="senha" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
									{/* <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)}/>
									{errors.password && <div className="text-danger fs-12">{errors.password}</div>} */}
								</div>
	
								<div className="text-center">
									<button type="submit" className="btn btn-primary btn-block">Sign In</button>
								</div>
							</Form>
							)}
							</Formik>
							<div className="new-account mt-2">
								<p className="mb-0">Não tem uma conta?{" "}
									<Link className="text-black" to="/page-register">Inscrever-me</Link>
								</p>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-5 d-flex box-skew1">						
					</div>
				</div>
			</div>
		</div>
		
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);