import { axiosInstance2 } from '../services/AxiosInstance2';
import SessionService from './SessionService';

class PacienteService {
  profissionalId = 1;
  empresaId = 1;

  async getPacientes(params) {
    // const cacheKey = `pacientes-${params?.nome}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }
    const response = await axiosInstance2.get(`/pacientes`, { params });
   // SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async getPacienteById(id) {
    // const cacheKey = `pacientes-${id}`;
    // const cachedData = SessionService.getCachedData(cacheKey);
    // if (cachedData) {
    //   return cachedData;
    // }

    const response = await axiosInstance2.get(`/pacientes/${id}`);
  //  SessionService.addToCache(cacheKey, response.data);
    return response.data;

  }


  async createPaciente(profissionalData) {   
    if (profissionalData.id == null) {
      const response = await axiosInstance2.post('/pacientes', profissionalData);
      SessionService.removeAll(`pacientes`);
      return response.data;
    } else {
      return await this.updatePaciente(profissionalData.id, profissionalData);
    }

  }

  async updatePaciente(id, profissionalData) {    
    const response = await axiosInstance2.put(`/pacientes/${id}`, profissionalData);
    SessionService.removeAll(`pacientes`);
    return response.data;

  }


  async deletePaciente(id) {
    SessionService.removeAll(`pacientes`);
    const response = await axiosInstance2.delete(`/pacientes/${id}`);
    return response.data;

  }
}

export default new PacienteService();
