
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { estadosService } from "../../services/EstadosService";

import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import InputMask from 'react-input-mask';
import { pessoaValidationSchema, pessoaInitialValues, pessoaPacienteValidationSchema, pessoaProfissionalValidationSchema } from "../../models/Pessoa";
//import PessoaService from "../../services/PessoaService";
import PacienteForm from "./PacienteForm";
import ProfissionalForm from "./ProfissionalForm";
import ProfissionalService from "../../services/ProfissionalService";
import PacienteService from "../../services/PacienteService";
import { useNavigate } from "react-router-dom";
import replaceNullsWithEmpty from "../../helper/ReplaceNullsWithEmpty";
import SecretariaService from "../../services/SecretariaService";

const PessoaForm = ({ pessoa }) => {

    const [formValues, setFormValues] = useState({})
    const [validateSchema, setValidateSchema] = useState({});
    const checkValidation = { Secretaria: pessoaValidationSchema, Paciente: pessoaPacienteValidationSchema, Profissional: pessoaProfissionalValidationSchema };

    const navigate = useNavigate();

    useEffect(() => {
        setValidateSchema(checkValidation[pessoa.tipo]);
        setFormValues(pessoaInitialValues);
        const loadForm = async () => {
            if (pessoa?.id != null) {
                try {
                    let data;
                    console.log(pessoa.tipo);
                    console.log(pessoa);
                    if (pessoa.tipo === 'Paciente') {
                        data = await PacienteService.getPacienteById(pessoa.id);
                    } else if (pessoa.tipo === 'Profissional') {
                        data = await ProfissionalService.getProfissionalById(pessoa.id);
                    } else {
                        data = await SecretariaService.getSecretariaById(pessoa.id);
                    }
                    setFormValues(replaceNullsWithEmpty(data));
                    // setFormValues(data);
                } catch (error) {
                    console.error('Erro ao obter configuração da empresa:', error);
                }
            }
        };

        loadForm();
    }, []);
    const saveProfissional = (data, { setSubmitting, setErrors }) => {
        const profissional = data;
        profissional.profissional.especialidades = profissional.profissional.especialidades.map(m => m.value);
        ProfissionalService.createProfissional(data).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
    }
    const savePaciente = (data, { setSubmitting, setErrors }) => {
        PacienteService.createPaciente(data).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
            navigate('/pacientes');
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
    }
    const savePessoa = (data, { setSubmitting, setErrors }) => {
        SecretariaService.createSecretaria(data).then(data => {
            toast.success("Salvo com sucesso", {
                position: "top-right",
                autoClose: 5000
            });
        }).catch(error => {
            if (error?.errors) {
                setErrors(error.errors);
                toast.warning(error.message, {
                    position: "top-right",
                    autoClose: 5000
                });
            }
        })
            .finally(() => {
                setSubmitting(false);
            });
    }

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        values.tipo_usuario = pessoa.tipo
        if (pessoa.tipo === "Profissional") {
            delete values.paciente;
            delete values.profissional_especialidades;
            saveProfissional(values, { setSubmitting, setErrors });
        } else if (pessoa.tipo === "Paciente") {
            delete values.profissional;
            values.senha = '12345678';
            savePaciente(values, { setSubmitting, setErrors });
        } else {
            values.senha = '12345678';
            delete values.paciente;
            delete values.profissional;
            savePessoa(values, { setSubmitting, setErrors });
        }


    };
    const FileInput = ({ field, form, ...props }) => {
        const handleChange = (event) => {
            const selectedFile = event.currentTarget.files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
                form.setFieldValue(field.name, reader.result);
            };
            reader.readAsDataURL(selectedFile);
        };

        return <input type="file" onChange={handleChange} {...props} />;
    };


    return (
        <Fragment>
            <div className="basic-form">

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={validateSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                        setTimeout(() => {
                            handleSubmit(values, { setSubmitting, setErrors });
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange, handleBlur, setFieldValue
                    }) => (
                        <Form>

                            <div className="row">
                                <div className={`form-group mb-3 col-lg-8`}>
                                    <label className="text-label">Nome</label>
                                    <Field
                                        type="text"
                                        name="nome"
                                        className={`form-control ${touched?.nome ? (errors.nome ? 'is-invalid' : 'is-valid') : ''}`}

                                    />
                                    <ErrorMessage name="nome" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">CPF</label>
                                    <Field
                                        name="cpf"
                                        className={`form-control ${touched?.cpf ? (errors.cpf ? 'is-invalid' : 'is-valid') : ''}`}
                                        as={InputMask}
                                        mask="999.999.999-99"
                                    />

                                    <ErrorMessage name="cpf" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">Data de Nascimento</label>
                                    <Field
                                        type="date"
                                        name="data_nascimento"
                                        className={`form-control ${touched?.data_nascimento ? (errors.data_nascimento ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="data_nascimento" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">Telefone</label>
                                    <Field
                                        name="telefone"
                                        as={InputMask}
                                        mask="(99) 99999-9999"
                                        className={`form-control ${touched?.telefone ? (errors.telefone ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="telefone" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">Celular</label>
                                    <Field
                                        name="celular"
                                        as={InputMask}
                                        mask="(99) 99999-9999"
                                        className={`form-control ${touched?.celular ? (errors.celular ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="celular" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-8">
                                    <label className="text-label">E-mail</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        className={`form-control ${touched?.email ? (errors.email ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">RG</label>
                                    <Field
                                        type="text"
                                        name="rg"
                                        className={`form-control ${touched?.rg ? (errors.rg ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="rg" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">Sexo</label>
                                    <Field
                                        name="sexo"
                                        as="select"
                                        className={`form-control ${touched?.sexo ? (errors.sexo ? 'is-invalid' : 'is-valid') : ''}`}
                                    >
                                        <option value="" >Selecione..</option>
                                        <option value="M" >Masculino</option>
                                        <option value="F" >Feminino</option>

                                    </Field>
                                    <ErrorMessage name="sexo" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-4">
                                    <label className="text-label">Gênero</label>
                                    <Field
                                        type="text"
                                        name="genero"
                                        className={`form-control ${touched?.genero ? (errors.genero ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="genero" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className={`form-group mb-3 col-lg-4 ${touched?.cep ? (errors.cep ? 'is-invalid' : 'is-valid') : ''}`}>
                                    <label className="text-label">CEP</label>
                                    <Field
                                        type="text"
                                        id="val-cep"
                                        className={`form-control ${touched?.cep ? (errors.cep ? 'is-invalid' : 'is-valid') : ''}`}
                                        as={InputMask}
                                        mask="99999-999"
                                        name="cep"
                                    />
                                    <ErrorMessage name="cep" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Logradouro</label>
                                    <Field
                                        type="text"
                                        name="logradouro"
                                        className={`form-control ${touched?.logradouro ? (errors.logradouro ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="logradouro" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Complemento</label>
                                    <Field
                                        type="text"
                                        name="complemento"
                                        className={`form-control ${touched?.complemento ? (errors.complemento ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="complemento" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Bairro</label>
                                    <Field
                                        type="text"
                                        name="bairro"
                                        className={`form-control ${touched?.bairro ? (errors.bairro ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="bairro" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Estado</label>
                                    <Field
                                        as="select"
                                        name="uf"
                                        className={`form-control ${touched?.uf ? (errors.uf ? 'is-invalid' : 'is-valid') : ''}`}
                                    >
                                        <option value="" disabled>Selecione um estado</option>
                                        {estadosService.map((estado) => (
                                            <option key={estado.sigla} value={estado.sigla}>
                                                {estado.descricao}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="uf" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>

                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Cidade</label>
                                    <Field
                                        type="text"
                                        name="cidade"
                                        className={`form-control ${touched?.cidade ? (errors.cidade ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="cidade" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-3 col-lg-6">
                                    <label className="text-label">Foto</label>
                                    <Field
                                        name="foto"
                                        component={FileInput}
                                        accept="image/*"
                                        className={`form-control ${touched?.foto ? (errors.foto ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="foto" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                                <div className="form-group mb-12 col-lg-12">
                                    <label className="text-label">Observações</label>
                                    <Field
                                        as="textarea"
                                        name="observacoes"
                                        rows={4}
                                        className={`form-control ${touched?.observacoes ? (errors.observacoes ? 'is-invalid' : 'is-valid') : ''}`}
                                    />
                                    <ErrorMessage name="observacoes" component="div" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                                </div>
                            </div>
                            {/* Adicionar if nos tipo para carregar os dados do formulario */}
                            {/* {JSON.stringify(errors)}
                            
                             <PacienteForm   errors={errors.paciente} /> 
                             <ProfissionalForm   errors={errors.profissional} />  */}
                            {pessoa.tipo === "Paciente" && <PacienteForm errors={errors.paciente} touched={touched?.paciente} />}

                            {(pessoa.tipo === "Profissional" && values?.profissional) && <ProfissionalForm errors={errors?.profissional} touched={touched?.profissional} handleChange={handleChange} handleBlur={handleBlur} values={values?.profissional} setFieldValue={setFieldValue} />}

                            <button
                                type="submit"
                                className="btn me-2 btn-primary"
                            >
                                Salvar
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </Fragment>
    );
};

export default PessoaForm;
